import React, { useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AddLicense } from 'components/license/AddLicense';
import { DownloadLicenseJsonInput, Mutable } from 'types';
import { HorizontalGroup, IconButton, Input } from '@grafana/ui';
import { LicenseTable } from 'components/license/LicenseTable';
import {
  ConfigLicenseFilterInput,
  InputMaybe,
  useLicensesQuery,
  useRegenerateLicenseFileMutation,
  useVesselsLazyQuery,
} from '__generated__/__admin';
import { useAppNotification } from 'notifications';
import { notEmpty } from 'helpers';
import { Filter } from 'components/filter/Filter';
import { filterToWhere } from 'utils/filter';

export const LicensePage = () => {
  const notifier = useAppNotification();
  const { search } = useLocation();
  const history = useHistory();
  const vesselId = useMemo(() => new URLSearchParams(search).get('vesselId'), [search]);
  const [vesselFilter, setVesselFilter] = useState<string>();
  const [installationIdFilter, setInstallationIdFilter] = useState<string>();
  const [createdByFilter, setCreatedByFilter] = useState<string>();
  const [vesselsQuery, { data: vesselData }] = useVesselsLazyQuery();

  useLayoutEffect(() => {
    if (vesselId) {
      vesselsQuery({ variables: { id: vesselId } });
    }
  }, [vesselsQuery, vesselId]);

  const where = useMemo(() => {
    const vesselWhere = filterToWhere(vesselFilter);
    const installationIdWhere = filterToWhere(installationIdFilter);
    const createdByWhere = filterToWhere(createdByFilter);

    const res: InputMaybe<Mutable<ConfigLicenseFilterInput>> = {};
    if (vesselId) {
      res.vesselId = { eq: vesselId };
    }
    if (vesselWhere) {
      res.vessel = { name: vesselWhere };
    }
    if (installationIdWhere) {
      res.installationId = installationIdWhere;
    }
    if (createdByWhere) {
      res.createdBy = createdByWhere;
    }

    if (Object.keys(res).length === 0) {
      return undefined;
    }
    return res as InputMaybe<ConfigLicenseFilterInput>;
  }, [vesselId, vesselFilter, installationIdFilter, createdByFilter]);

  const { data, refetch } = useLicensesQuery({ variables: { where } });

  const [redownloadLicenseMutation] = useRegenerateLicenseFileMutation();

  const redownloadLicense = useCallback(
    (id: string) => {
      const asyncFunc = async (id: string) => {
        try {
          const config = data?.configLicenses?.filter(notEmpty).find((c) => c.id === id);
          if (config) {
            const res = await redownloadLicenseMutation({ variables: { licenseId: id } });
            if (res.errors) {
              notifier.error('License creation failed');
            } else {
              notifier.success('License created');
              const vesselName = config.vessel?.name;
              const installationId = config.installationId;
              if (!vesselName || !installationId) {
                notifier.warning('Vessel name or installation id is missing');
                return;
              }
              downloadJson({
                json: res.data?.regenerateLicenseFile ?? undefined,
                vessel: vesselName,
                installationId: installationId,
              });
            }
          }
        } catch (e) {
          notifier.error('Failed creating license');
        }
      };

      asyncFunc(id);
    },
    [redownloadLicenseMutation, data, notifier]
  );

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <HorizontalGroup justify="space-between">
          <AddLicense refetch={() => refetch()} downloadJson={downloadJson} />
          <HorizontalGroup>
            {vesselId && vesselData?.vessels?.[0]?.name ? (
              <Input
                value={vesselData.vessels[0].name}
                disabled
                prefix="Vessel: "
                suffix={
                  <IconButton
                    name="times"
                    tooltip="Remove this filter"
                    onClick={() => {
                      history.push({ search: '?tab=licenses' });
                    }}
                  />
                }
              />
            ) : (
              <Filter<string> label="Vessel" setFilter={(e) => setVesselFilter(e)} filter={vesselFilter || ''} />
            )}
            <Filter<string>
              label="Installation id"
              setFilter={(e) => setInstallationIdFilter(e)}
              filter={installationIdFilter || ''}
            />
            <Filter<string>
              label="Created by"
              setFilter={(e) => setCreatedByFilter(e)}
              filter={createdByFilter || ''}
            />
          </HorizontalGroup>
        </HorizontalGroup>
      </div>
      <LicenseTable data={data} downloadAction={redownloadLicense} />
    </>
  );
};

const downloadJson = ({ json, vessel, installationId }: DownloadLicenseJsonInput) => {
  if (json === undefined || vessel === undefined || installationId === undefined) {
    return;
  }

  let a = document.createElement('a');
  let file = new Blob([json], { type: 'text/plain' });
  a.href = URL.createObjectURL(file);
  a.download = `license_${vessel}_${installationId}.lic`;
  a.click();
};
