import React, { FC, useMemo, useLayoutEffect } from 'react';
import {
  Form,
  Field,
  Input,
  Button,
  Select,
  HorizontalGroup,
  DatePickerWithInput,
  Spinner,
  InputControl,
  LoadingPlaceholder,
  ConfirmButton,
} from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import {
  useVesselsLazyQuery,
  useCompaniesLazyQuery,
  useSetVesselVisibilityMutation,
  VesselsDocument,
  AllVesselsDocument,
  CreateVesselMutationVariables,
  UpdateVesselMutationVariables,
  ApplicationType,
} from '__generated__/__admin';
import { AccessKeyGenerator } from './AccessKeyGenerator';
import { humanizeApplicationNameHelper } from 'utils/helpers';

type SubmitVariables = CreateVesselMutationVariables | UpdateVesselMutationVariables;

const EMPTY_VESSEL: SubmitVariables = {
  id: '',
  name: '',
  imo: '',
  projectNumber: 0,
  companyId: null,
  comment: '',
  applicationType: null as any as ApplicationType,
};

type Props = {
  vesselId?: string;
  onSubmit: (variables: SubmitVariables) => void;
  loading?: boolean;
};

export const VesselOperation: FC<Props> = ({ vesselId, onSubmit, loading }) => {
  const [companiesQuery, { data: companiesData }] = useCompaniesLazyQuery();

  const [vesselQuery, { data: vesselData }] = useVesselsLazyQuery();
  const vessel = vesselData?.vessels?.[0];
  const [setVesselVisibilityMutation] = useSetVesselVisibilityMutation({
    refetchQueries: [{ query: VesselsDocument, variables: { id: vesselId } }, { query: AllVesselsDocument }],
  });

  useLayoutEffect(() => {
    if (vesselId !== undefined) {
      vesselQuery({ variables: { id: vesselId } });
    } 

    companiesQuery(); 
  }, [vesselId, vesselQuery, companiesQuery]);

  const selectableCompanies: Array<SelectableValue<number>> = useMemo(
    () =>
      companiesData?.companies?.filter((e) => e?.id).map((e) => ({ label: e?.companyName, value: e?.id })) ||
      ([] as Array<SelectableValue<number>>),
    [companiesData]
  );

  const selectableApplicationTypes: Array<SelectableValue<ApplicationType>> = useMemo(
    () =>
      Object.values(ApplicationType)
      .filter((e) => e !== ApplicationType.COMPACT)
      .map((e) => ({
        label: humanizeApplicationNameHelper(e),
        value: e,
      })),
    []
  );

  const rawDefaultValues = vessel === null ? undefined : vessel;
  if (vesselId && rawDefaultValues === undefined) {
    return <LoadingPlaceholder text={'Loading vessel'} />;
  }

  const defaultValues: SubmitVariables = rawDefaultValues
    ? {
        id: rawDefaultValues.id,
        name: rawDefaultValues.name || '',
        imo: rawDefaultValues.imo,
        projectNumber: rawDefaultValues.projectNumber,
        companyId: rawDefaultValues.company?.id,
        fatDate: rawDefaultValues.fatDate,
        startupDate: rawDefaultValues.startupDate,
        comment: rawDefaultValues.comment,
        applicationType: rawDefaultValues.applicationType
      }
    : EMPTY_VESSEL;

  return (
    <>
    <Form<SubmitVariables> validateOn="onSubmit" onSubmit={onSubmit} defaultValues={defaultValues} style={{paddingBottom: '23px'}}>
      {({ register, control, formState, errors, watch }) => {
        let currentApplicationType = watch('applicationType', defaultValues.applicationType);

        return (
        <>
          <HorizontalGroup>
            <Field label="IMO number">
              <Input width={25} {...register('imo')} />
            </Field>
            <Field label="Vessel Name">
              <Input
                width={25}
                invalid={!!errors.name}
                {...register('name', { required: 'Vessel name must be provided', minLength: 3, maxLength: 50 })}
              />
            </Field>
            <Field label="Project number">
              <Input type="number" width={25} {...register('projectNumber', { valueAsNumber: true })} />
            </Field>
          </HorizontalGroup>
          <HorizontalGroup>
            <Field label="FAT date">
              <InputControl
                render={({ field }) => <DatePickerWithInput {...field} width={25} />}
                name="fatDate"
                control={control}
              />
            </Field>
            <Field label="Startup date">
              <InputControl
                render={({ field }) => <DatePickerWithInput {...field} width={25} />}
                name="startupDate"
                control={control}
              />
            </Field>
          </HorizontalGroup>
          <HorizontalGroup>
            <Field label="Application type">
              <InputControl
                render={({ field }) => (
                  <Select
                    invalid={!!errors.applicationType}
                    width={25}
                    onChange={(e) => field.onChange(e.value)}
                    options={selectableApplicationTypes}
                    value={selectableApplicationTypes.find((e) => e.value === field.value)}
                    disabled={vessel?.company !== null && vesselId !== undefined}
                    />
                )}
                rules={{
                  required: 'Application type must be provided',
                }}
                name='applicationType'
                control={control}/>
            </Field>
            <Field label="Company">
              <InputControl
                render={({ field }) => (
                  <Select
                    invalid={!!errors.companyId}
                    width={25}
                    onChange={(e) => field.onChange(e.value)}
                    value={
                      vessel?.company
                        ? {
                            label: vessel.company.companyName,
                            value: vessel.company.id,
                          }
                        : selectableCompanies.find((e) => e.value === field.value)
                    }
                    options={selectableCompanies}
                    placeholder="Select company"
                    disabled={(vesselId !== undefined && vessel?.company !== null) || currentApplicationType !== ApplicationType.ECOMATE}
                  />
                )}
                name="companyId"
                control={control}
                rules={{
                  validate: value => {
                    if (currentApplicationType === ApplicationType.ECOMATE && !value) {
                      return 'Company must be provided for Ecomate vessels';
                    }
                    return true;
                  }
                }}
              />
            </Field>
          </HorizontalGroup>
          <HorizontalGroup>
            <Field label="Comment">
              <Input width={77} {...register('comment')} />
            </Field>
          </HorizontalGroup>
          <HorizontalGroup justify="space-between">
            <Button disabled={!formState.isDirty || loading} variant="primary" type="submit">
              {loading ? <Spinner /> : 'Submit'}
            </Button>
            {vesselId && (
              <ConfirmButton
                confirmText={`Are you sure you want to ${
                  vessel?.deleted ? 'display' : 'hide'
                } this vessel?`}
                closeOnConfirm
                onConfirm={() =>
                  setVesselVisibilityMutation({
                    variables: { id: vesselId, hidden: !vessel?.deleted },
                  })
                }
              >
                {vessel?.deleted ? 'Display' : 'Hide'}
              </ConfirmButton>
            )}
          </HorizontalGroup>
        </>
        );
      }}
    </Form>
    {vesselId && <AccessKeyGenerator id={vesselId} cloud={vessel?.company !== null} />}
    </>
  );
};
