import { FieldError } from 'react-hook-form';
import { css } from '@emotion/css';
import React, { useMemo, useCallback, useState } from 'react';
import {
  Modal,
  Button,
  Select,
  Form,
  Field,
  InputControl,
  VerticalGroup,
  HorizontalGroup,
  FileDropzone,
  Checkbox,
  TextArea,
} from '@grafana/ui';
import { ConfigBackupPayload, UploadConfigInput, VesselBase } from 'types';
import { SelectableValue } from '@grafana/data';
import { ErrorCode, FileError } from 'react-dropzone';
import { FileListItem } from './FileList';
import { ConfigsDocument, useAllVesselsQuery, useUploadConfigMutation } from '__generated__/__admin';
import { notEmpty } from 'helpers';
import { useAppNotification } from 'notifications';
import { ProgressBar } from 'components/ProgressBar';

interface UploadConfigFormProps {
  loading: boolean;
  canForce?: boolean;
  onSubmit: (config: UploadConfigInput) => void;
  progress?: number;
}

const UploadConfigForm: React.FC<UploadConfigFormProps> = ({ loading, onSubmit, canForce, progress }) => {
  const { data } = useAllVesselsQuery();
  const vesselOptions = useMemo(() => {
    return (
      (data?.vessels
        ?.filter(notEmpty)
        .map((e) => ({ label: e.imo ? `${e.name} (IMO: ${e.imo})` : e.name, value: e })) as Array<
        SelectableValue<VesselBase>
      >) || []
    );
  }, [data]);

  return (
    <Form<UploadConfigInput>
      defaultValues={{
        vesselId: '',
        comment: '',
        config: '',
        force: false,
        flags: 0,
        fromVessel: false,
      }}
      onSubmit={onSubmit}
    >
      {({ register, control, errors, formState, setError }) => {
        return (
          <VerticalGroup width="1000px">
            <HorizontalGroup align="flex-start">
              <VerticalGroup align="flex-start" width="320px">
                <Field label="Vessel" invalid={!!errors.vesselId} error={errors.vesselId?.message}>
                  <InputControl
                    render={({ field }) => (
                      <Select<VesselBase>
                        width={40}
                        onChange={(e) => field.onChange(e.value?.id)}
                        value={vesselOptions?.find((e) => e.value?.id === field.value)}
                        options={vesselOptions}
                        placeholder="Select vessel"
                      />
                    )}
                    name="vesselId"
                    control={control}
                    rules={{
                      required: 'Vessel must be provided',
                    }}
                  />
                </Field>
                <Field label="Comment" invalid={!!errors.comment} error="Comment must be provided">
                  <TextArea
                    {...register('comment', { required: false })}
                    className={css`
                      height: 200px;
                      width: 320px;
                    `}
                  />
                </Field>
              </VerticalGroup>
              <Field
                label="Config file"
                invalid={!!errors.config}
                error={(errors.config as FieldError | undefined)?.message}
              >
                <InputControl
                  name="config"
                  rules={{
                    required: 'Config file must be provided',
                  }}
                  control={control}
                  render={({ field }) => (
                    <FileDropzone
                      fileListRenderer={(file, removeFile) => {
                        return (
                          <FileListItem
                            file={file}
                            removeFile={removeFile}
                            onFileAdded={field.onChange}
                            onFileRemoved={() => {
                              field.onChange('');
                              setError('config', { message: 'Config file must be provided' });
                            }}
                          />
                        );
                      }}
                      options={{
                        validator: (file) => {
                          const errors: FileError[] = [];
                          if (file.size > 20_000_000) {
                            errors.push({
                              message: 'File too large (20MB)',
                              code: ErrorCode.FileTooLarge,
                            });
                          }
                          if (file.size < 100_000) {
                            errors.push({
                              message: 'File too small (100KB)',
                              code: ErrorCode.FileTooLarge,
                            });
                          }
                          if (file.type !== '' && file.type !== '.db') {
                            errors.push({
                              message: 'Wrong filetype (.db)',
                              code: ErrorCode.FileInvalidType,
                            });
                          }
                          if (errors.length) {
                            return errors;
                          }
                          return null;
                        },
                        maxSize: 20_000_000,
                        minSize: 100_000,
                        multiple: false,
                      }}
                    />
                  )}
                />
              </Field>
            </HorizontalGroup>
            {canForce && <Checkbox {...register('force')} label="Force upload" />}
            <HorizontalGroup align="center">
              {loading && <ProgressBar value={(progress ?? 0) * 100} width={600} height={20} />}
              {!loading && (
                <Button type="submit" disabled={!formState.isDirty} variant="primary">
                  Save
                </Button>
              )}
            </HorizontalGroup>
          </VerticalGroup>
        );
      }}
    </Form>
  );
};

export const UploadConfig = () => {
  const notifier = useAppNotification();
  const [isOpen, setIsOpen] = useState(false);
  const [canForce, setCanForce] = useState(false);
  const [progress, setProgress] = useState(0);
  const { data: vesselData } = useAllVesselsQuery();

  const [mutation, { loading }] = useUploadConfigMutation({
    onError: (e) => {
      console.error('Error', e);
    },
    context: {
      fetchOptions: {
        useUpload: true,
        onProgress: (ev: ProgressEvent) => {
          setProgress(ev.loaded / ev.total);
        },
      },
    },
    update(cache, { data }) {
      if (data) {
        const { configs } = cache.readQuery<ConfigBackupPayload>({ query: ConfigsDocument }) || {
          configs: [],
        };
        cache.writeQuery({
          query: ConfigsDocument,
          data: {
            configs: [data.uploadConfig.configBackup, ...configs],
          },
        });
      }
    },
  });

  const handleOnSubmit = useCallback(
    (variables: UploadConfigInput) => {
      const asyncFunc = async (variables: UploadConfigInput) => {
        try {
          if (!loading) {
            if (variables.force && !confirm('This will overwrite the previously uploaded config')) {
              return;
            }
            const res = await mutation({
              variables,
            });
            setProgress(0);
            if (res.data?.uploadConfig.errors?.length) {
              res.data.uploadConfig.errors.forEach((error) => {
                notifier.warning(error.message);
                setCanForce(true);
              });
            }
            if (res.data?.uploadConfig.configBackup?.id) {
              notifier.success(`Config for vessel ${res.data?.uploadConfig.configBackup?.vesselId} uploaded`);
              setCanForce(false);
              setIsOpen(false);
            }
          }
        } catch (e) {
          const error = JSON.parse(JSON.stringify(e));
          notifier.error(
            `Error uploading config for vessel ${
              vesselData?.vessels?.filter(notEmpty).find((e) => e.id === variables.vesselId)?.name ?? variables.vesselId
            }: ${error.message}`
          );
        }
      };
      asyncFunc(variables);
    },
    [loading, mutation, notifier, vesselData]
  );

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Add</Button>
      <Modal title="Upload Monitoring System config" isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <UploadConfigForm loading={loading} onSubmit={handleOnSubmit} canForce={canForce} progress={progress} />
      </Modal>
    </>
  );
};
