import React, { useState, useMemo, useEffect } from 'react';

import { VesselsTable } from 'components/vessel/VesselsTable';
import { useLocation } from 'react-router-dom';
import { HorizontalGroup, LoadingPlaceholder } from '@grafana/ui';
import { AddVessel } from 'components/vessel/AddVessel';
import { EditVesselModal } from 'components/vessel/EditVesselModal';
import {
  ApplicationType,
  InputMaybe,
  SortEnumType,
  VesselFilterInput,
  useAllVesselsQuery,
  useCompaniesQuery,
} from '__generated__/__admin';
import { Filter } from 'components/filter/Filter';
import { SelectableValue } from '@grafana/data';
import { filterToWhere } from 'utils/filter';
import { Mutable } from 'types';
import { humanizeApplicationNameHelper } from 'utils/helpers';

export const VesselPage = () => {
  // Get the company ID from the URL, using react-router-dom. example url: /a/krohnemarine-admin-app?tab=vessels&companyId=1
  const { search } = useLocation();
  const companyId = useMemo(() => new URLSearchParams(search).get('companyId'), [search]);

  const [editId, setEditId] = useState<string>();
  const [addOpen, setAddOpen] = useState(false);
  const [companyFilter, setCompanyFilter] = useState<Array<SelectableValue<number>>>();
  const [vesselFilter, setVesselFilter] = useState<string>();
  const [imoFilter, setImoFilter] = useState<string>();
  const [projectNumberFilter, setProjectNumberFilter] = useState<string>();
  const [applicationTypeFilter, setApplicationTypeFilter] = useState<Array<SelectableValue<ApplicationType>>>();

  const { data: companiesData } = useCompaniesQuery({ variables: { order: [{ companyName: SortEnumType.ASC }] } });

  const where = useMemo<InputMaybe<VesselFilterInput> | undefined>(() => {
    const companyWhere = filterToWhere(companyFilter);
    const vesselWhere = filterToWhere(vesselFilter);
    const imoWhere = filterToWhere(imoFilter);
    // Convert the project number to a number
    const projectNumberWhere = projectNumberFilter ? { eq: parseInt(projectNumberFilter, 10) } : undefined;
    const applicationTypeWhere = filterToWhere(applicationTypeFilter);

    const res: InputMaybe<Mutable<VesselFilterInput>> = {};
    if (companyWhere) {
      res.companyId = companyWhere;
    }
    if (vesselWhere) {
      res.name = vesselWhere;
    }
    if (imoWhere) {
      res.imo = imoWhere;
    }
    if (projectNumberWhere) {
      res.projectNumber = projectNumberWhere;
    }
    if (applicationTypeWhere) {
      res.applicationType = applicationTypeWhere;
    }

    if (Object.keys(res).length === 0) {
      return undefined;
    }
    return res as InputMaybe<VesselFilterInput>;
  }, [companyFilter, vesselFilter, imoFilter, projectNumberFilter, applicationTypeFilter]);

  const selectableCompanies = useMemo(
    () => companiesData?.companies?.map((c) => ({ label: c?.companyName, value: c?.id })) ?? [],
    [companiesData]
  );

  const selectableApplicationTypes: Array<SelectableValue<ApplicationType>> = useMemo(
    () =>
      Object.values(ApplicationType)
      .filter((e) => e !== ApplicationType.COMPACT)
      .map((e) => ({
        label: humanizeApplicationNameHelper(e),
        value: e,
      })),
    []
  );

  useEffect(() => {
    if (companyId) {
      const id = parseInt(companyId, 10);
      const company = selectableCompanies?.find((c) => c?.value === id);
      if (company) {
        setCompanyFilter([company]);
      }
    }
  }, [companyId, selectableCompanies]);

  const { data, loading } = useAllVesselsQuery({ variables: { where } });

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <HorizontalGroup justify="space-between">
          <HorizontalGroup>
            <AddVessel onDismiss={() => setAddOpen(false)} isOpen={addOpen} />
            {loading && <LoadingPlaceholder text={'Loading vessels...'} style={{ marginBottom: '0px' }} />}
          </HorizontalGroup>
          <HorizontalGroup>
            <Filter<Array<SelectableValue<number>>>
              label="Company"
              options={selectableCompanies}
              setFilter={(e) => setCompanyFilter(e)}
              filter={companyFilter || []}
            />
            <Filter<string> label="Vessel" setFilter={(e) => setVesselFilter(e)} filter={vesselFilter || ''} />
            <Filter<string> label="IMO" setFilter={(e) => setImoFilter(e)} filter={imoFilter || ''} />
            <Filter<string>
              label="Project number"
              setFilter={(e) => setProjectNumberFilter(e)}
              filter={projectNumberFilter || ''}
            />
            <Filter<Array<SelectableValue<ApplicationType>>>
              label="Application type"
              setFilter={(e) => setApplicationTypeFilter(e)}
              options={selectableApplicationTypes}
              filter={applicationTypeFilter || []}/>
          </HorizontalGroup>
        </HorizontalGroup>
      </div>
      <VesselsTable onEdit={setEditId} data={data} />
      <EditVesselModal onDismiss={() => setEditId(undefined)} vesselId={editId} />
    </>
  );
};
