import React, { useState } from 'react';
import { ToolbarButton } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import { FilterPopup } from './FilterPopup';
import { Toggletip } from './Toggletip';
import { isArray } from '@apollo/client/utilities';

interface BaseProps<T extends SelectableValue[] | string | number> {
  label: string;
  filter: T;
  options?: SelectableValue[];
  setFilter: (filter: T | undefined) => void;
}

interface PropsAsSelectable<T extends SelectableValue[]> extends BaseProps<T> {}
interface PropsAsSimple<T extends string | number> extends BaseProps<T> {}

type Props<T> = T extends string | number ? PropsAsSimple<T> : PropsAsSelectable<SelectableValue[]>;

export const Filter = <T,>({ label, filter, options, setFilter }: Props<T>) => {
  const [open, setOpen] = useState(false);

  const getButtonText = () => {
    if (typeof filter === 'string' && filter.length) {
      return filter;
    }
    if (isArray(filter)) {
      if (filter.length === 1) {
        return `${filter[0].label}`;
      }
      if (filter.length > 1) {
        return `${filter[0].label} (+${filter.length - 1})`;
      }
    }
    return label;
  };

  const getFilterPopup = () => {
    if (!isArray(options) && (typeof filter === 'string' || typeof filter === 'number')) {
      return FilterPopup<string | number>({
        onClose: () => {
          setOpen(false);
        },
        label,
        filterValue: filter,
        setFilter: setFilter as (filter: string | number | undefined) => void,
      });
    }
    if (isArray(options) && isArray(filter)) {
      return FilterPopup({
        onClose: () => {
          setOpen(false);
        },
        label,
        options: options,
        filterValue: filter,
        setFilter: setFilter as (filter: SelectableValue[] | string | undefined) => void,
      });
    }
    return undefined;
  };

  return (
    <Toggletip
      content={getFilterPopup() || <>Filter type not supported</>}
      fitContent
      closeButton={false}
      placement="bottom"
      show={open}
    >
      <ToolbarButton
        onClick={() => {
          setOpen((e) => !e);
        }}
        isOpen={open}
      >
        {getButtonText()}
      </ToolbarButton>
    </Toggletip>
  );
};
