import React, { FC, useCallback, useState } from 'react';

import { Button, Input, Modal, useStyles2 } from '@grafana/ui';
import { getStyles } from 'utils/styles';
import { VesselOperation } from './VesselOperation';
import { useAppNotification } from 'notifications';
import { useCreateVesselMutation, CreateVesselMutationVariables, AllVesselsDocument, ApplicationType } from '__generated__/__admin';

interface Props extends Partial<React.ComponentProps<typeof Modal>> {}

export const AddVessel: FC<Props> = (props) => {
  const notifier = useAppNotification();
  const { verticalSpacing } = useStyles2(getStyles());
  const [isOpen, setIsOpen] = useState(false);

  const [createVesselMutation, { data: response, loading }] = useCreateVesselMutation({
    refetchQueries: [{ query: AllVesselsDocument }],
  });

  const onAddVessel = useCallback(
    (variables: CreateVesselMutationVariables) => {
      const asyncFunc = async (variables: CreateVesselMutationVariables) => {
        try {
          if (!loading) {
            if(variables.applicationType !== ApplicationType.ECOMATE) {
              variables.companyId = null;
            }

            const res = await createVesselMutation({ variables });

            if (res.errors && res.data?.createVessel?.accessKey) {
              notifier.warning('Vessel created, with issues');
            } else if (res.errors) {
              notifier.error('Vessel creation failed');
            } else if (res.data?.createVessel?.accessKey && variables.applicationType === ApplicationType.ECOMATE) {
              notifier.success('Cloud based vessel created');
            } else if (res.data?.createVessel && variables.applicationType !== ApplicationType.ECOMATE) {
              notifier.success('Standalone vessel created');
            } else {
              notifier.warning('Vessel creation not completed');
            }
          }
        } catch (e) {
          notifier.error('Failed creating vessel');
        }
      };
      asyncFunc(variables);
    },
    [createVesselMutation, loading, notifier]
  );

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Add</Button>
      <Modal closeOnEscape title="Add" isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <VesselOperation onSubmit={onAddVessel} loading={loading} />
        {response?.createVessel?.accessKey && (
          <>
            <div className={verticalSpacing}>Vessel created. Accesskey below.</div>
            <Input style={{ maxWidth: 100 }} value={response.createVessel.accessKey} />
            <Button
              className={verticalSpacing}
              onClick={() => {
                if (response.createVessel?.accessKey) {
                  navigator.clipboard.writeText(response.createVessel.accessKey);
                }
              }}
            >
              Copy to clipboard
            </Button>
          </>
        )}
      </Modal>
    </>
  );
};
