import { SelectableValue } from '@grafana/data';
import { notEmpty } from 'helpers';

export function getFilteredOptions(options: SelectableValue[], filterValues?: SelectableValue[]): SelectableValue[] {
  if (!filterValues) {
    return [];
  }

  return options.filter((option) => filterValues.some((filtered) => filtered.value === option.value));
}

export function filterToWhere(filter?: Array<SelectableValue<any>> | string) {
  if (!filter || filter.length === 0) {
    return undefined;
  }
  if (typeof filter === 'string') {
    return {
      contains: filter,
    };
  }
  return {
    in: filter.map((f) => f.value).filter(notEmpty),
  };
}
