import React, { PureComponent } from 'react';
import { AppPluginMeta, PluginConfigPageProps } from '@grafana/data';
import { AdminAppSettings } from '../module';
import { Setup } from 'components/Setup';

interface Props extends PluginConfigPageProps<AppPluginMeta<AdminAppSettings>> {}

export class ConfigPage extends PureComponent<Props> {
  render() {
    const { plugin } = this.props;

    return <Setup plugin={plugin} />;
  }
}
