import React, { useLayoutEffect, useCallback } from 'react';
import { Form, Field, Input, Button, Modal, LoadingPlaceholder, Spinner } from '@grafana/ui';
import {
  CompaniesDocument,
  CompaniesQuery,
  UpdateCompanyMutationVariables,
  useCompaniesLazyQuery,
  useUpdateCompanyMutation,
} from '__generated__/__admin';
import { useAppNotification } from 'notifications';

interface Props {
  onDismiss?: () => void;
  companyId?: number;
}

export const EditCompanyModal: React.FC<Props> = ({ companyId, onDismiss }) => {
  const notifier = useAppNotification();
  const [updateCompanyMutation, { loading }] = useUpdateCompanyMutation({
    onError: (e) => {
      notifier.error('Updating company failed', e.message);
    },
    update: (cache, { data }) => {
      if (data) {
        const { companies } = cache.readQuery<CompaniesQuery>({ query: CompaniesDocument }) ?? { companies: [] };
        if (companies) {
          cache.writeQuery({
            query: CompaniesDocument,
            data: {
              companies: [...companies, data.updateCompany],
            },
          });
        }
      }
    },
  });

  const onUpdateCompany = useCallback(
    (variables: Omit<UpdateCompanyMutationVariables, 'id'>) => {
      const asyncFunc = async (variables: UpdateCompanyMutationVariables) => {
        try {
          const res = await updateCompanyMutation({ variables });
          if (res.errors) {
            notifier.error(`Company update failed`);
          } else {
            notifier.success(`Company updated`);
            onDismiss?.();
          }
        } catch (e) {
          console.error(e);
          notifier.error(`Company update failed`);
        }
      };
      if (!loading && companyId) {
        asyncFunc({ ...variables, id: companyId });
      }
    },
    [updateCompanyMutation, loading, companyId, notifier, onDismiss]
  );

  const [query, { data }] = useCompaniesLazyQuery();

  useLayoutEffect(() => {
    if (companyId) {
      query({ variables: { where: { id: { eq: companyId } } } });
    }
  }, [companyId, query]);

  const rawDefaultValues = data?.companies?.[0] ?? undefined;

  const defaultValues: UpdateCompanyMutationVariables = rawDefaultValues
    ? {
        id: rawDefaultValues.id,
        companyName: rawDefaultValues.companyName,
        comment: rawDefaultValues.comment || '',
      }
    : {
        id: 0,
        companyName: '',
        comment: '',
      };

  return (
    <Modal title="Edit company" isOpen={!!companyId} onDismiss={onDismiss} closeOnEscape>
      {companyId && rawDefaultValues === undefined ? (
        <LoadingPlaceholder text={'Loading company'} />
      ) : (
        <Form<UpdateCompanyMutationVariables>
          validateOn="onSubmit"
          defaultValues={defaultValues}
          onSubmit={onUpdateCompany}
        >
          {({ register, control, formState, errors }) => (
            <>
              <Field label="Name">
                <Input
                  invalid={!!errors.companyName}
                  {...register('companyName', {
                    required: 'Company name must be provided',
                    minLength: 3,
                    maxLength: 50,
                  })}
                />
              </Field>
              <Field label="Comment">
                <Input {...register('comment')} />
              </Field>
              <Field label="Grafana Org ID">
                <Input value={data?.companies?.[0]?.grafanaOrgId ?? ''} disabled />
              </Field>
              <Button disabled={!formState.isDirty} variant="primary" type="submit">
                {loading ? <Spinner /> : 'Submit'}
              </Button>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
};
