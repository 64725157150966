import React, { useState } from 'react';
import { Input, Button, Form, Spinner, Field, Modal } from '@grafana/ui';

import { CreateCompanyVariables } from 'types';
import { CompaniesDocument, CompaniesQuery, SortEnumType, useCreateCompanyMutation } from '__generated__/__admin';
import { useAppNotification } from 'notifications';
import { CopyInput } from 'components/CopyInput';

export const AddCompany = () => {
  const notifier = useAppNotification();
  const [isOpen, setIsOpen] = useState(false);
  const [createCompany, { data, loading, reset }] = useCreateCompanyMutation({
    onError: (e) => {
      notifier.error(`Error creating company: ${e.message}`);
    },
    update: (cache, { data }) => {
      if (data) {
        const { companies } = cache.readQuery<CompaniesQuery>({
          query: CompaniesDocument,
          variables: { order: [{ companyName: SortEnumType.ASC }] },
        }) ?? { companies: [] };
        if (companies) {
          cache.writeQuery({
            query: CompaniesDocument,
            data: {
              companies: [...companies, data.createCompany?.company],
            },
            variables: { order: [{ companyName: SortEnumType.ASC }] },
          });
        }
      }
    },
  });

  return (
    <>
      <Button disabled onClick={() => setIsOpen(true)}>Add</Button>
      <Modal
        closeOnEscape
        title="Create Company"
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          reset();
        }}
      >
        <Form<CreateCompanyVariables> onSubmit={(e) => createCompany({ variables: e })}>
          {({ register, errors, reset }) => {
            if (!isOpen) {
              reset();
            }
            return (
              <>
                <Field label="Name" invalid={!!errors.name} error="Name must be 3-50 characters long">
                  <Input
                    {...register('name', {
                      required: true,
                      minLength: 3,
                      maxLength: 50,
                      validate: (e) => e.length >= 3,
                    })}
                  />
                </Field>
                <Field label="Comment">
                  <Input {...register('comment')} />
                </Field>
                <Button type="submit" disabled={loading || data !== undefined} variant="primary">
                  {loading ? <Spinner /> : 'Submit'}
                </Button>
              </>
            );
          }}
        </Form>
        {data?.createCompany?.readOnlyConnectionString && (
          <Field
            style={{ marginTop: 10 }}
            label="Company created"
            description="This is the connection string for the company database. This is the only time this is visible. Copy it now."
          >
            <CopyInput text={data?.createCompany?.readOnlyConnectionString} />
          </Field>
        )}
      </Modal>
    </>
  );
};
