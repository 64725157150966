import React, { FC, useState, useEffect } from 'react';
import { IconButton, Input } from '@grafana/ui';

interface Props {
  text: string;
}

export const CopyInput: FC<Props> = ({ text }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => {
        clearTimeout(timeout);
      };
    }
    return undefined;
  }, [copied]);

  return (
    <Input
      width={50} // magic number, sorry
      value={text}
      suffix={
        <IconButton
          name={copied ? 'check' : 'clipboard-alt'}
          tooltip={copied ? 'Copied' : 'Copy to clipboard'}
          onClick={() => {
            navigator.clipboard.writeText(text);
            setCopied(true);
          }}
        />
      }
    />
  );
};
