import React from 'react';
import { Alert, Button, HorizontalGroup, Modal } from '@grafana/ui';

type Props = {
  isOpen: boolean;
  error?: React.ReactNode;
  onDismiss: () => void;
  onConfirm: () => void;
};

export const DisablePluginModal = ({ isOpen, error, onDismiss, onConfirm }: Props) => (
  <Modal title="Disable bunker app" isOpen={isOpen} onDismiss={onDismiss}>
    <p>Are you sure? Disabling the plugin will remove the bunker functions</p>
    <HorizontalGroup>
      <Button variant="destructive" onClick={onConfirm}>
        Disable
      </Button>
      <Button variant="secondary" onClick={onDismiss}>
        Cancel
      </Button>
    </HorizontalGroup>
    {error && <Alert title="Disable failed">{error}</Alert>}
  </Modal>
);
