import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/** THIS FILE IS AUTO-GENERATED **/
/** DO NOT EDIT **/
/* eslint-disable */

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
}

export interface AddAdminPluginToCompanyInput {
  readonly companyId: Scalars['Int']['input'];
}

export interface AddDashboardsToCompanyInput {
  readonly companyId: Scalars['Int']['input'];
}

export interface AddDataSourcesToCompanyInput {
  readonly companyId: Scalars['Int']['input'];
  readonly password: Scalars['String']['input'];
  readonly username: Scalars['String']['input'];
}

export enum AddOrUpdateAction {
  ADDED = 'ADDED',
  DEPRECATED = 'DEPRECATED',
  UNCHANGED = 'UNCHANGED',
  UPDATED = 'UPDATED'
}

export interface AddOrUpdateLocodePortInput {
  readonly countryCode?: InputMaybe<Scalars['String']['input']>;
  readonly inEuropeanUnion?: InputMaybe<Scalars['Boolean']['input']>;
  readonly latitude?: InputMaybe<Scalars['Float']['input']>;
  readonly locodePart?: InputMaybe<Scalars['String']['input']>;
  readonly longitude?: InputMaybe<Scalars['Float']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly nameWithoutDiacritics?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly subdivision?: InputMaybe<Scalars['String']['input']>;
}

export interface AddOrUpdateLocodePortsInput {
  readonly preview?: Scalars['Boolean']['input'];
  readonly url?: InputMaybe<Scalars['String']['input']>;
}

export enum ApplicationType {
  CARGOMASTER = 'CARGOMASTER',
  COMPACT = 'COMPACT',
  ECOMATE = 'ECOMATE',
  ECOMATE_STANDALONE = 'ECOMATE_STANDALONE'
}

export interface ApplicationTypeOperationFilterInput {
  readonly eq?: InputMaybe<ApplicationType>;
  readonly in?: InputMaybe<ReadonlyArray<ApplicationType>>;
  readonly neq?: InputMaybe<ApplicationType>;
  readonly nin?: InputMaybe<ReadonlyArray<ApplicationType>>;
}

export enum ApplyPolicy {
  AFTER_RESOLVER = 'AFTER_RESOLVER',
  BEFORE_RESOLVER = 'BEFORE_RESOLVER',
  VALIDATION = 'VALIDATION'
}

export interface BooleanOperationFilterInput {
  readonly eq?: InputMaybe<Scalars['Boolean']['input']>;
  readonly neq?: InputMaybe<Scalars['Boolean']['input']>;
}

export interface CompanyFilterInput {
  readonly and?: InputMaybe<ReadonlyArray<CompanyFilterInput>>;
  readonly comment?: InputMaybe<StringOperationFilterInput>;
  readonly companyName?: InputMaybe<StringOperationFilterInput>;
  readonly dbAdminConnectionString?: InputMaybe<StringOperationFilterInput>;
  readonly dbRoConnectionString?: InputMaybe<StringOperationFilterInput>;
  readonly dbRwConnectionString?: InputMaybe<StringOperationFilterInput>;
  readonly deleted?: InputMaybe<BooleanOperationFilterInput>;
  readonly grafanaOrgId?: InputMaybe<IntOperationFilterInput>;
  readonly id?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<CompanyFilterInput>>;
  readonly vessels?: InputMaybe<ListFilterInputTypeOfVesselFilterInput>;
}

export interface CompanySortInput {
  readonly comment?: InputMaybe<SortEnumType>;
  readonly companyName?: InputMaybe<SortEnumType>;
  readonly dbAdminConnectionString?: InputMaybe<SortEnumType>;
  readonly dbRoConnectionString?: InputMaybe<SortEnumType>;
  readonly dbRwConnectionString?: InputMaybe<SortEnumType>;
  readonly deleted?: InputMaybe<SortEnumType>;
  readonly grafanaOrgId?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
}

export enum ConfigComparison {
  EQUAL = 'EQUAL',
  EQUAL_BOTH_HAS_AUTO_UPDATES = 'EQUAL_BOTH_HAS_AUTO_UPDATES',
  EQUAL_LOCAL_HAS_AUTO_UPDATES = 'EQUAL_LOCAL_HAS_AUTO_UPDATES',
  EQUAL_REMOTE_HAS_AUTO_UPDATES = 'EQUAL_REMOTE_HAS_AUTO_UPDATES',
  LOCAL_NEWER = 'LOCAL_NEWER',
  MISMATCH = 'MISMATCH',
  REMOTE_NEWER = 'REMOTE_NEWER'
}

export interface ConfigLicenseFilterInput {
  readonly and?: InputMaybe<ReadonlyArray<ConfigLicenseFilterInput>>;
  readonly comment?: InputMaybe<StringOperationFilterInput>;
  readonly createdAt?: InputMaybe<DateTimeOperationFilterInput>;
  readonly createdBy?: InputMaybe<StringOperationFilterInput>;
  readonly deleted?: InputMaybe<BooleanOperationFilterInput>;
  readonly expirationDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly installationId?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ConfigLicenseFilterInput>>;
  readonly users?: InputMaybe<ListFilterInputTypeOfConfigLicenseUsersFilterInput>;
  readonly vessel?: InputMaybe<VesselFilterInput>;
  readonly vesselId?: InputMaybe<StringOperationFilterInput>;
}

export interface ConfigLicenseSortInput {
  readonly comment?: InputMaybe<SortEnumType>;
  readonly createdAt?: InputMaybe<SortEnumType>;
  readonly createdBy?: InputMaybe<SortEnumType>;
  readonly deleted?: InputMaybe<SortEnumType>;
  readonly expirationDate?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly installationId?: InputMaybe<SortEnumType>;
  readonly vessel?: InputMaybe<VesselSortInput>;
  readonly vesselId?: InputMaybe<SortEnumType>;
}

export interface ConfigLicenseUsersFilterInput {
  readonly and?: InputMaybe<ReadonlyArray<ConfigLicenseUsersFilterInput>>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly license?: InputMaybe<ConfigLicenseFilterInput>;
  readonly licenseId?: InputMaybe<UuidOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ConfigLicenseUsersFilterInput>>;
  readonly password?: InputMaybe<StringOperationFilterInput>;
  readonly username?: InputMaybe<StringOperationFilterInput>;
}

export interface ConfigMetaFilterInput {
  readonly and?: InputMaybe<ReadonlyArray<ConfigMetaFilterInput>>;
  readonly comment?: InputMaybe<StringOperationFilterInput>;
  readonly deleted?: InputMaybe<BooleanOperationFilterInput>;
  readonly flags?: InputMaybe<IntOperationFilterInput>;
  readonly fromVessel?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<ConfigMetaFilterInput>>;
  readonly softwareBuild?: InputMaybe<IntOperationFilterInput>;
  readonly softwareMajor?: InputMaybe<IntOperationFilterInput>;
  readonly softwareMinor?: InputMaybe<IntOperationFilterInput>;
  readonly softwarePatch?: InputMaybe<IntOperationFilterInput>;
  readonly timeStamp?: InputMaybe<DateTimeOperationFilterInput>;
  readonly user?: InputMaybe<StringOperationFilterInput>;
  readonly versionHistoryId?: InputMaybe<IntOperationFilterInput>;
  readonly vessel?: InputMaybe<VesselFilterInput>;
  readonly vesselId?: InputMaybe<StringOperationFilterInput>;
}

export interface ConfigMetaSortInput {
  readonly comment?: InputMaybe<SortEnumType>;
  readonly deleted?: InputMaybe<SortEnumType>;
  readonly fileName?: InputMaybe<SortEnumType>;
  readonly flags?: InputMaybe<SortEnumType>;
  readonly fromVessel?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly softwareBuild?: InputMaybe<SortEnumType>;
  readonly softwareMajor?: InputMaybe<SortEnumType>;
  readonly softwareMinor?: InputMaybe<SortEnumType>;
  readonly softwarePatch?: InputMaybe<SortEnumType>;
  readonly timeStamp?: InputMaybe<SortEnumType>;
  readonly user?: InputMaybe<SortEnumType>;
  readonly versionHistoryId?: InputMaybe<SortEnumType>;
  readonly vessel?: InputMaybe<VesselSortInput>;
  readonly vesselId?: InputMaybe<SortEnumType>;
}

export interface ConfigQueryInput {
  readonly allVersions: Scalars['Boolean']['input'];
}

export interface CreateCompanyInput {
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly companyName: Scalars['String']['input'];
  readonly grafanaOrgId?: InputMaybe<Scalars['Int']['input']>;
}

export interface CreateVesselLicenseInput {
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly computerName?: InputMaybe<Scalars['String']['input']>;
  readonly expirationDate: Scalars['DateTime']['input'];
  readonly installationId: Scalars['String']['input'];
  readonly licenseKey: Scalars['String']['input'];
  readonly vesselId: Scalars['String']['input'];
}

export interface DateTimeOperationFilterInput {
  readonly eq?: InputMaybe<Scalars['DateTime']['input']>;
  readonly gt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly gte?: InputMaybe<Scalars['DateTime']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
  readonly lt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly lte?: InputMaybe<Scalars['DateTime']['input']>;
  readonly neq?: InputMaybe<Scalars['DateTime']['input']>;
  readonly ngt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly ngte?: InputMaybe<Scalars['DateTime']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly nlte?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface DeleteCompanyInput {
  readonly id: Scalars['Int']['input'];
}

export interface DeleteLicenseFileInput {
  readonly id?: InputMaybe<Scalars['String']['input']>;
}

export interface DeleteVesselLicenseInput {
  readonly id: Scalars['Int']['input'];
}

export interface GenerateAccessKeyInput {
  readonly vesselId: Scalars['String']['input'];
}

export interface GenerateLicenseFileInput {
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly installationId: Scalars['String']['input'];
  readonly users?: InputMaybe<ReadonlyArray<InputMaybe<LicenseUserInput>>>;
  readonly vesselId?: InputMaybe<Scalars['String']['input']>;
}

export interface IntOperationFilterInput {
  readonly eq?: InputMaybe<Scalars['Int']['input']>;
  readonly gt?: InputMaybe<Scalars['Int']['input']>;
  readonly gte?: InputMaybe<Scalars['Int']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']['input']>>>;
  readonly lt?: InputMaybe<Scalars['Int']['input']>;
  readonly lte?: InputMaybe<Scalars['Int']['input']>;
  readonly neq?: InputMaybe<Scalars['Int']['input']>;
  readonly ngt?: InputMaybe<Scalars['Int']['input']>;
  readonly ngte?: InputMaybe<Scalars['Int']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['Int']['input']>;
  readonly nlte?: InputMaybe<Scalars['Int']['input']>;
}

export interface LicenseUserInput {
  readonly password?: InputMaybe<Scalars['String']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
}

export interface ListFilterInputTypeOfConfigLicenseUsersFilterInput {
  readonly all?: InputMaybe<ConfigLicenseUsersFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<ConfigLicenseUsersFilterInput>;
  readonly some?: InputMaybe<ConfigLicenseUsersFilterInput>;
}

export interface ListFilterInputTypeOfConfigMetaFilterInput {
  readonly all?: InputMaybe<ConfigMetaFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<ConfigMetaFilterInput>;
  readonly some?: InputMaybe<ConfigMetaFilterInput>;
}

export interface ListFilterInputTypeOfVesselFilterInput {
  readonly all?: InputMaybe<VesselFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<VesselFilterInput>;
  readonly some?: InputMaybe<VesselFilterInput>;
}

export interface ListFilterInputTypeOfVesselLicenseFilterInput {
  readonly all?: InputMaybe<VesselLicenseFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']['input']>;
  readonly none?: InputMaybe<VesselLicenseFilterInput>;
  readonly some?: InputMaybe<VesselLicenseFilterInput>;
}

export interface LocodePortFilterInput {
  readonly and?: InputMaybe<ReadonlyArray<LocodePortFilterInput>>;
  readonly countryCode?: InputMaybe<StringOperationFilterInput>;
  readonly createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly deleted?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly inEuropeanUnion?: InputMaybe<BooleanOperationFilterInput>;
  readonly lastChangedDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly locode?: InputMaybe<StringOperationFilterInput>;
  readonly locodePart?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly nameWithoutDiacritics?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<LocodePortFilterInput>>;
  readonly status?: InputMaybe<StringOperationFilterInput>;
  readonly subdivision?: InputMaybe<StringOperationFilterInput>;
}

export interface LocodePortSortInput {
  readonly countryCode?: InputMaybe<SortEnumType>;
  readonly createdDate?: InputMaybe<SortEnumType>;
  readonly deleted?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly inEuropeanUnion?: InputMaybe<SortEnumType>;
  readonly lastChangedDate?: InputMaybe<SortEnumType>;
  readonly locode?: InputMaybe<SortEnumType>;
  readonly locodePart?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly nameWithoutDiacritics?: InputMaybe<SortEnumType>;
  readonly status?: InputMaybe<SortEnumType>;
  readonly subdivision?: InputMaybe<SortEnumType>;
}

export interface LoginAndVerifyInput {
  readonly password?: InputMaybe<Scalars['String']['input']>;
  readonly username?: InputMaybe<Scalars['String']['input']>;
}

export interface MigrateCompanySchemaInput {
  readonly id: Scalars['Int']['input'];
}

export interface RegenerateLicenseFileInput {
  readonly id?: InputMaybe<Scalars['String']['input']>;
}

export interface SetVisibilityInput {
  readonly hidden: Scalars['Boolean']['input'];
  readonly vesselId: Scalars['String']['input'];
}

export enum SortEnumType {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface StringOperationFilterInput {
  readonly and?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly contains?: InputMaybe<Scalars['String']['input']>;
  readonly endsWith?: InputMaybe<Scalars['String']['input']>;
  readonly eq?: InputMaybe<Scalars['String']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly ncontains?: InputMaybe<Scalars['String']['input']>;
  readonly nendsWith?: InputMaybe<Scalars['String']['input']>;
  readonly neq?: InputMaybe<Scalars['String']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  readonly nstartsWith?: InputMaybe<Scalars['String']['input']>;
  readonly or?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly startsWith?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateCompanyConnectionStringInput {
  readonly companyId: Scalars['Int']['input'];
  readonly connectionString?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateCompanyInput {
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly companyName: Scalars['String']['input'];
  readonly grafanaOrgId?: InputMaybe<Scalars['Int']['input']>;
  readonly id: Scalars['Int']['input'];
}

export interface UpdateCompanyStandardListsInput {
  readonly id: Scalars['Int']['input'];
}

export interface UpdateVesselInput {
  readonly applicationType: ApplicationType;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly companyId?: InputMaybe<Scalars['Int']['input']>;
  readonly fatDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly hidden?: InputMaybe<Scalars['Boolean']['input']>;
  readonly imo?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly projectNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly startupDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly version?: InputMaybe<Scalars['String']['input']>;
  readonly vesselId: Scalars['String']['input'];
}

export interface UpdateVesselLicenseInput {
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly computerName?: InputMaybe<Scalars['String']['input']>;
  readonly expirationDate: Scalars['DateTime']['input'];
  readonly id: Scalars['Int']['input'];
  readonly installationId: Scalars['String']['input'];
  readonly licenseKey: Scalars['String']['input'];
  readonly vesselId: Scalars['String']['input'];
}

/** Input type for uploading a config file */
export interface UploadConfigInput {
  /** The comment */
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  /** The config file */
  readonly config?: InputMaybe<Scalars['Upload']['input']>;
  /** The flags */
  readonly flags: Scalars['Int']['input'];
  /** Ignore existing config */
  readonly force?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the config from the vessel */
  readonly fromVessel: Scalars['Boolean']['input'];
  /** The vessel id */
  readonly vesselId?: InputMaybe<Scalars['String']['input']>;
}

export interface UuidOperationFilterInput {
  readonly eq?: InputMaybe<Scalars['UUID']['input']>;
  readonly gt?: InputMaybe<Scalars['UUID']['input']>;
  readonly gte?: InputMaybe<Scalars['UUID']['input']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>>;
  readonly lt?: InputMaybe<Scalars['UUID']['input']>;
  readonly lte?: InputMaybe<Scalars['UUID']['input']>;
  readonly neq?: InputMaybe<Scalars['UUID']['input']>;
  readonly ngt?: InputMaybe<Scalars['UUID']['input']>;
  readonly ngte?: InputMaybe<Scalars['UUID']['input']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']['input']>>>;
  readonly nlt?: InputMaybe<Scalars['UUID']['input']>;
  readonly nlte?: InputMaybe<Scalars['UUID']['input']>;
}

export interface VerifyIdentityInput {
  readonly token?: InputMaybe<Scalars['String']['input']>;
}

export interface VesselFilterInput {
  readonly accessKey?: InputMaybe<StringOperationFilterInput>;
  readonly and?: InputMaybe<ReadonlyArray<VesselFilterInput>>;
  readonly applicationType?: InputMaybe<ApplicationTypeOperationFilterInput>;
  readonly comment?: InputMaybe<StringOperationFilterInput>;
  readonly company?: InputMaybe<CompanyFilterInput>;
  readonly companyId?: InputMaybe<IntOperationFilterInput>;
  readonly configs?: InputMaybe<ListFilterInputTypeOfConfigMetaFilterInput>;
  readonly deleted?: InputMaybe<BooleanOperationFilterInput>;
  readonly fatDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<StringOperationFilterInput>;
  readonly imo?: InputMaybe<StringOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<VesselFilterInput>>;
  readonly projectNumber?: InputMaybe<IntOperationFilterInput>;
  readonly startupDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly version?: InputMaybe<StringOperationFilterInput>;
  readonly vesselLicenses?: InputMaybe<ListFilterInputTypeOfVesselLicenseFilterInput>;
}

export interface VesselInput {
  readonly applicationType: ApplicationType;
  readonly comment?: InputMaybe<Scalars['String']['input']>;
  readonly companyId?: InputMaybe<Scalars['Int']['input']>;
  readonly fatDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly hidden?: InputMaybe<Scalars['Boolean']['input']>;
  readonly imo?: InputMaybe<Scalars['String']['input']>;
  readonly name: Scalars['String']['input'];
  readonly projectNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly startupDate?: InputMaybe<Scalars['DateTime']['input']>;
  readonly version?: InputMaybe<Scalars['String']['input']>;
}

export interface VesselLicenseFilterInput {
  readonly and?: InputMaybe<ReadonlyArray<VesselLicenseFilterInput>>;
  readonly comment?: InputMaybe<StringOperationFilterInput>;
  readonly computerName?: InputMaybe<StringOperationFilterInput>;
  readonly deleted?: InputMaybe<BooleanOperationFilterInput>;
  readonly expirationDate?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<IntOperationFilterInput>;
  readonly installationId?: InputMaybe<StringOperationFilterInput>;
  readonly licenseKey?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<VesselLicenseFilterInput>>;
  readonly vessel?: InputMaybe<VesselFilterInput>;
  readonly vesselId?: InputMaybe<StringOperationFilterInput>;
}

export interface VesselSortInput {
  readonly accessKey?: InputMaybe<SortEnumType>;
  readonly applicationType?: InputMaybe<SortEnumType>;
  readonly comment?: InputMaybe<SortEnumType>;
  readonly company?: InputMaybe<CompanySortInput>;
  readonly companyId?: InputMaybe<SortEnumType>;
  readonly deleted?: InputMaybe<SortEnumType>;
  readonly fatDate?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly imo?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly projectNumber?: InputMaybe<SortEnumType>;
  readonly startupDate?: InputMaybe<SortEnumType>;
  readonly version?: InputMaybe<SortEnumType>;
}

export type VesselBaseFragment = { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null };

export type VesselMetaFragment = { readonly __typename: 'Vessel', readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType };

export type ConfigMetaFragment = { readonly __typename: 'ConfigMeta', readonly versionHistoryId: number, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly timeStamp: any, readonly user: string | null, readonly fromVessel: boolean, readonly vesselId: string | null, readonly deleted: boolean, readonly flags: number };

export type VesselWithConfigFragment = { readonly __typename: 'Vessel', readonly configs: ReadonlyArray<{ readonly __typename: 'ConfigMeta', readonly versionHistoryId: number, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly timeStamp: any, readonly user: string | null, readonly fromVessel: boolean, readonly vesselId: string | null, readonly deleted: boolean, readonly flags: number } | null> | null };

export type CompanyBaseFragment = { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null };

export type VesselWithCompanyBaseFragment = { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null };

export type CompanyWithVesselsFragment = { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null, readonly vessels: ReadonlyArray<{ readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null } | null> | null };

export type ConfigFragment = { readonly __typename: 'ConfigMeta', readonly id: string | null, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly timeStamp: any, readonly user: string | null, readonly versionHistoryId: number, readonly comment: string | null, readonly vesselId: string | null, readonly vessel: { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null } | null };

export type VesselFragment = { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly company: { readonly __typename: 'Company', readonly name: string } | null, readonly configs: ReadonlyArray<{ readonly __typename: 'ConfigMeta', readonly id: string | null, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly timeStamp: any, readonly user: string | null, readonly versionHistoryId: number, readonly comment: string | null, readonly vesselId: string | null, readonly vessel: { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null } | null } | null> | null };

export type CompanyFragment = { readonly __typename: 'Company', readonly id: number, readonly comment: string | null, readonly name: string, readonly vessels: ReadonlyArray<{ readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly company: { readonly __typename: 'Company', readonly name: string } | null, readonly configs: ReadonlyArray<{ readonly __typename: 'ConfigMeta', readonly id: string | null, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly timeStamp: any, readonly user: string | null, readonly versionHistoryId: number, readonly comment: string | null, readonly vesselId: string | null, readonly vessel: { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null } | null } | null> | null } | null> | null };

export type UserFragment = { readonly __typename: 'ConfigLicenseUsers', readonly id: any, readonly licenseId: any, readonly password: string | null, readonly username: string | null };

export type ConfigLicenseFragment = { readonly __typename: 'ConfigLicense', readonly id: any, readonly installationId: string | null, readonly expirationDate: any | null, readonly createdAt: any, readonly createdBy: string | null, readonly comment: string | null, readonly vessel: { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null } | null, readonly users: ReadonlyArray<{ readonly __typename: 'ConfigLicenseUsers', readonly id: any, readonly licenseId: any, readonly password: string | null, readonly username: string | null } | null> | null };

export type VesselLicenseFragment = { readonly __typename: 'VesselLicense', readonly comment: string | null, readonly computerName: string | null, readonly expirationDate: any, readonly id: number, readonly installationId: string | null, readonly licenseKey: string | null, readonly vesselId: string | null };

export type UpdateVesselMutationVariables = Exact<{
  id: Scalars['String']['input'];
  imo?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  fatDate?: InputMaybe<Scalars['DateTime']['input']>;
  startupDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  projectNumber?: InputMaybe<Scalars['Int']['input']>;
  applicationType: ApplicationType;
}>;


export type UpdateVesselMutation = { readonly updateVessel: { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType } | null };

export type SetVesselVisibilityMutationVariables = Exact<{
  id: Scalars['String']['input'];
  hidden: Scalars['Boolean']['input'];
}>;


export type SetVesselVisibilityMutation = { readonly setVesselVisibility: { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType } | null };

export type CreateVesselMutationVariables = Exact<{
  imo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  companyId?: InputMaybe<Scalars['Int']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  projectNumber?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  startupDate?: InputMaybe<Scalars['DateTime']['input']>;
  fatDate?: InputMaybe<Scalars['DateTime']['input']>;
  applicationType: ApplicationType;
}>;


export type CreateVesselMutation = { readonly createVessel: { readonly __typename: 'Vessel', readonly id: string, readonly accessKey: string | null } | null };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  companyName: Scalars['String']['input'];
  comment: Scalars['String']['input'];
}>;


export type UpdateCompanyMutation = { readonly updateCompany: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null };

export type DeleteLicenseFileMutationVariables = Exact<{
  licenseId: Scalars['String']['input'];
}>;


export type DeleteLicenseFileMutation = { readonly deleteLicenseFile: string | null };

export type RegenerateLicenseFileMutationVariables = Exact<{
  licenseId: Scalars['String']['input'];
}>;


export type RegenerateLicenseFileMutation = { readonly regenerateLicenseFile: string | null };

export type GenerateLicenseFileMutationVariables = Exact<{
  installationId: Scalars['String']['input'];
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  vesselId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<ReadonlyArray<InputMaybe<LicenseUserInput>>>;
}>;


export type GenerateLicenseFileMutation = { readonly generateLicenseFile: string | null };

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateCompanyMutation = { readonly createCompany: { readonly __typename: 'CreateCompanyResult', readonly readOnlyConnectionString: string | null, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null } | null };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteCompanyMutation = { readonly deleteCompany: { readonly __typename: 'Company', readonly id: number } | null };

export type GenerateAccessKeyMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GenerateAccessKeyMutation = { readonly generateAccessKey: string | null };

export type UploadConfigMutationVariables = Exact<{
  comment?: InputMaybe<Scalars['String']['input']>;
  config: Scalars['Upload']['input'];
  flags: Scalars['Int']['input'];
  force: Scalars['Boolean']['input'];
  fromVessel: Scalars['Boolean']['input'];
  vesselId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UploadConfigMutation = { readonly uploadConfig: { readonly __typename: 'UploadConfigPayload', readonly configBackup: { readonly __typename: 'ConfigBackup', readonly id: string | null, readonly vesselId: string | null, readonly fileName: string | null, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly versionHistoryId: number, readonly fromVessel: boolean, readonly timeStamp: any, readonly comment: string | null, readonly flags: number, readonly user: string | null, readonly deleted: boolean } | null, readonly errors: ReadonlyArray<{ readonly __typename: 'ConfigError', readonly comparison: ConfigComparison, readonly message: string }> | null } };

export type AllVesselsQueryVariables = Exact<{
  where?: InputMaybe<VesselFilterInput>;
  order?: InputMaybe<ReadonlyArray<VesselSortInput>>;
}>;


export type AllVesselsQuery = { readonly vessels: ReadonlyArray<{ readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null, readonly configs: ReadonlyArray<{ readonly __typename: 'ConfigMeta', readonly versionHistoryId: number, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly timeStamp: any, readonly user: string | null, readonly fromVessel: boolean, readonly vesselId: string | null, readonly deleted: boolean, readonly flags: number } | null> | null } | null> | null };

export type VesselsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VesselsQuery = { readonly vessels: ReadonlyArray<{ readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null, readonly configs: ReadonlyArray<{ readonly __typename: 'ConfigMeta', readonly versionHistoryId: number, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly timeStamp: any, readonly user: string | null, readonly fromVessel: boolean, readonly vesselId: string | null, readonly deleted: boolean, readonly flags: number } | null> | null } | null> | null };

export type ConfigsQueryVariables = Exact<{
  where?: InputMaybe<ConfigMetaFilterInput>;
  order?: InputMaybe<ReadonlyArray<ConfigMetaSortInput>>;
  input?: InputMaybe<ConfigQueryInput>;
}>;


export type ConfigsQuery = { readonly configs: ReadonlyArray<{ readonly __typename: 'ConfigMeta', readonly id: string | null, readonly softwareMajor: number, readonly softwareMinor: number, readonly softwarePatch: number, readonly softwareBuild: number, readonly timeStamp: any, readonly user: string | null, readonly versionHistoryId: number, readonly comment: string | null, readonly vesselId: string | null, readonly vessel: { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null, readonly companyId: number | null, readonly projectNumber: number | null, readonly fatDate: any | null, readonly startupDate: any | null, readonly version: string | null, readonly deleted: boolean, readonly applicationType: ApplicationType, readonly company: { readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null } | null } | null> | null };

export type CompaniesQueryVariables = Exact<{
  where?: InputMaybe<CompanyFilterInput>;
  order?: InputMaybe<ReadonlyArray<CompanySortInput>>;
}>;


export type CompaniesQuery = { readonly companies: ReadonlyArray<{ readonly __typename: 'Company', readonly id: number, readonly companyName: string, readonly grafanaOrgId: number | null, readonly comment: string | null } | null> | null };

export type ConfigFilesQueryVariables = Exact<{
  configId: Scalars['String']['input'];
}>;


export type ConfigFilesQuery = { readonly configFile: string | null };

export type LicensesQueryVariables = Exact<{
  where?: InputMaybe<ConfigLicenseFilterInput>;
  order?: InputMaybe<ReadonlyArray<ConfigLicenseSortInput>>;
}>;


export type LicensesQuery = { readonly configLicenses: ReadonlyArray<{ readonly __typename: 'ConfigLicense', readonly id: any, readonly installationId: string | null, readonly expirationDate: any | null, readonly createdAt: any, readonly createdBy: string | null, readonly comment: string | null, readonly vessel: { readonly __typename: 'Vessel', readonly id: string, readonly name: string | null, readonly imo: string | null, readonly comment: string | null } | null, readonly users: ReadonlyArray<{ readonly __typename: 'ConfigLicenseUsers', readonly id: any, readonly licenseId: any, readonly password: string | null, readonly username: string | null } | null> | null } | null> | null };

export type VesselLicensesQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type VesselLicensesQuery = { readonly vesselLicenses: ReadonlyArray<{ readonly __typename: 'VesselLicense', readonly comment: string | null, readonly computerName: string | null, readonly expirationDate: any, readonly id: number, readonly installationId: string | null, readonly licenseKey: string | null, readonly vesselId: string | null } | null> | null };

export const ConfigMetaFragmentDoc = gql`
    fragment ConfigMeta on ConfigMeta {
  versionHistoryId
  softwareMajor
  softwareMinor
  softwarePatch
  softwareBuild
  timeStamp
  user
  fromVessel
  vesselId
  deleted
  flags
}
    `;
export const VesselWithConfigFragmentDoc = gql`
    fragment VesselWithConfig on Vessel {
  configs {
    ...ConfigMeta
  }
}
    ${ConfigMetaFragmentDoc}`;
export const CompanyBaseFragmentDoc = gql`
    fragment CompanyBase on Company {
  id
  companyName
  grafanaOrgId
  comment
}
    `;
export const VesselBaseFragmentDoc = gql`
    fragment VesselBase on Vessel {
  id
  name
  imo
  comment
}
    `;
export const VesselMetaFragmentDoc = gql`
    fragment VesselMeta on Vessel {
  companyId
  projectNumber
  fatDate
  startupDate
  version
  deleted
  applicationType
}
    `;
export const VesselWithCompanyBaseFragmentDoc = gql`
    fragment VesselWithCompanyBase on Vessel {
  ...VesselBase
  ...VesselMeta
  company {
    ...CompanyBase
  }
}
    ${VesselBaseFragmentDoc}
${VesselMetaFragmentDoc}
${CompanyBaseFragmentDoc}`;
export const CompanyWithVesselsFragmentDoc = gql`
    fragment CompanyWithVessels on Company {
  ...CompanyBase
  vessels {
    ...VesselWithCompanyBase
  }
}
    ${CompanyBaseFragmentDoc}
${VesselWithCompanyBaseFragmentDoc}`;
export const ConfigFragmentDoc = gql`
    fragment Config on ConfigMeta {
  id
  softwareMajor
  softwareMinor
  softwarePatch
  softwareBuild
  timeStamp
  user
  versionHistoryId
  comment
  vesselId
  vessel {
    ...VesselWithCompanyBase
  }
}
    ${VesselWithCompanyBaseFragmentDoc}`;
export const VesselFragmentDoc = gql`
    fragment Vessel on Vessel {
  id
  name
  imo
  company {
    name: companyName
  }
  configs {
    ...Config
  }
}
    ${ConfigFragmentDoc}`;
export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  name: companyName
  comment
  vessels {
    ...Vessel
  }
}
    ${VesselFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on ConfigLicenseUsers {
  id
  licenseId
  password
  username
}
    `;
export const ConfigLicenseFragmentDoc = gql`
    fragment ConfigLicense on ConfigLicense {
  id
  installationId
  expirationDate
  createdAt
  createdBy
  comment
  vessel {
    ...VesselBase
  }
  users {
    ...User
  }
}
    ${VesselBaseFragmentDoc}
${UserFragmentDoc}`;
export const VesselLicenseFragmentDoc = gql`
    fragment VesselLicense on VesselLicense {
  comment
  computerName
  expirationDate
  id
  installationId
  licenseKey
  vesselId
}
    `;
export const UpdateVesselDocument = gql`
    mutation UpdateVessel($id: String!, $imo: String, $companyId: Int, $fatDate: DateTime, $startupDate: DateTime, $name: String!, $comment: String, $version: String, $projectNumber: Int, $applicationType: ApplicationType!) {
  updateVessel(
    input: {vesselId: $id, imo: $imo, name: $name, companyId: $companyId, version: $version, projectNumber: $projectNumber, fatDate: $fatDate, startupDate: $startupDate, comment: $comment, applicationType: $applicationType}
  ) {
    ...VesselBase
    ...VesselMeta
  }
}
    ${VesselBaseFragmentDoc}
${VesselMetaFragmentDoc}`;
export type UpdateVesselMutationFn = Apollo.MutationFunction<UpdateVesselMutation, UpdateVesselMutationVariables>;

/**
 * __useUpdateVesselMutation__
 *
 * To run a mutation, you first call `useUpdateVesselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVesselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVesselMutation, { data, loading, error }] = useUpdateVesselMutation({
 *   variables: {
 *      id: // value for 'id'
 *      imo: // value for 'imo'
 *      companyId: // value for 'companyId'
 *      fatDate: // value for 'fatDate'
 *      startupDate: // value for 'startupDate'
 *      name: // value for 'name'
 *      comment: // value for 'comment'
 *      version: // value for 'version'
 *      projectNumber: // value for 'projectNumber'
 *      applicationType: // value for 'applicationType'
 *   },
 * });
 */
export function useUpdateVesselMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVesselMutation, UpdateVesselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVesselMutation, UpdateVesselMutationVariables>(UpdateVesselDocument, options);
      }
export type UpdateVesselMutationHookResult = ReturnType<typeof useUpdateVesselMutation>;
export type UpdateVesselMutationResult = Apollo.MutationResult<UpdateVesselMutation>;
export type UpdateVesselMutationOptions = Apollo.BaseMutationOptions<UpdateVesselMutation, UpdateVesselMutationVariables>;
export const SetVesselVisibilityDocument = gql`
    mutation SetVesselVisibility($id: String!, $hidden: Boolean!) {
  setVesselVisibility(input: {vesselId: $id, hidden: $hidden}) {
    ...VesselBase
    ...VesselMeta
  }
}
    ${VesselBaseFragmentDoc}
${VesselMetaFragmentDoc}`;
export type SetVesselVisibilityMutationFn = Apollo.MutationFunction<SetVesselVisibilityMutation, SetVesselVisibilityMutationVariables>;

/**
 * __useSetVesselVisibilityMutation__
 *
 * To run a mutation, you first call `useSetVesselVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVesselVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVesselVisibilityMutation, { data, loading, error }] = useSetVesselVisibilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      hidden: // value for 'hidden'
 *   },
 * });
 */
export function useSetVesselVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<SetVesselVisibilityMutation, SetVesselVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetVesselVisibilityMutation, SetVesselVisibilityMutationVariables>(SetVesselVisibilityDocument, options);
      }
export type SetVesselVisibilityMutationHookResult = ReturnType<typeof useSetVesselVisibilityMutation>;
export type SetVesselVisibilityMutationResult = Apollo.MutationResult<SetVesselVisibilityMutation>;
export type SetVesselVisibilityMutationOptions = Apollo.BaseMutationOptions<SetVesselVisibilityMutation, SetVesselVisibilityMutationVariables>;
export const CreateVesselDocument = gql`
    mutation CreateVessel($imo: String, $name: String!, $companyId: Int, $version: String, $projectNumber: Int, $comment: String, $startupDate: DateTime, $fatDate: DateTime, $applicationType: ApplicationType!) {
  createVessel(
    input: {imo: $imo, name: $name, companyId: $companyId, version: $version, projectNumber: $projectNumber, fatDate: $fatDate, startupDate: $startupDate, comment: $comment, applicationType: $applicationType}
  ) {
    id
    accessKey
  }
}
    `;
export type CreateVesselMutationFn = Apollo.MutationFunction<CreateVesselMutation, CreateVesselMutationVariables>;

/**
 * __useCreateVesselMutation__
 *
 * To run a mutation, you first call `useCreateVesselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVesselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVesselMutation, { data, loading, error }] = useCreateVesselMutation({
 *   variables: {
 *      imo: // value for 'imo'
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      version: // value for 'version'
 *      projectNumber: // value for 'projectNumber'
 *      comment: // value for 'comment'
 *      startupDate: // value for 'startupDate'
 *      fatDate: // value for 'fatDate'
 *      applicationType: // value for 'applicationType'
 *   },
 * });
 */
export function useCreateVesselMutation(baseOptions?: Apollo.MutationHookOptions<CreateVesselMutation, CreateVesselMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVesselMutation, CreateVesselMutationVariables>(CreateVesselDocument, options);
      }
export type CreateVesselMutationHookResult = ReturnType<typeof useCreateVesselMutation>;
export type CreateVesselMutationResult = Apollo.MutationResult<CreateVesselMutation>;
export type CreateVesselMutationOptions = Apollo.BaseMutationOptions<CreateVesselMutation, CreateVesselMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: Int!, $companyName: String!, $comment: String!) {
  updateCompany(input: {id: $id, companyName: $companyName, comment: $comment}) {
    ...CompanyBase
  }
}
    ${CompanyBaseFragmentDoc}`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyName: // value for 'companyName'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteLicenseFileDocument = gql`
    mutation DeleteLicenseFile($licenseId: String!) {
  deleteLicenseFile(input: {id: $licenseId})
}
    `;
export type DeleteLicenseFileMutationFn = Apollo.MutationFunction<DeleteLicenseFileMutation, DeleteLicenseFileMutationVariables>;

/**
 * __useDeleteLicenseFileMutation__
 *
 * To run a mutation, you first call `useDeleteLicenseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLicenseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLicenseFileMutation, { data, loading, error }] = useDeleteLicenseFileMutation({
 *   variables: {
 *      licenseId: // value for 'licenseId'
 *   },
 * });
 */
export function useDeleteLicenseFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLicenseFileMutation, DeleteLicenseFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLicenseFileMutation, DeleteLicenseFileMutationVariables>(DeleteLicenseFileDocument, options);
      }
export type DeleteLicenseFileMutationHookResult = ReturnType<typeof useDeleteLicenseFileMutation>;
export type DeleteLicenseFileMutationResult = Apollo.MutationResult<DeleteLicenseFileMutation>;
export type DeleteLicenseFileMutationOptions = Apollo.BaseMutationOptions<DeleteLicenseFileMutation, DeleteLicenseFileMutationVariables>;
export const RegenerateLicenseFileDocument = gql`
    mutation RegenerateLicenseFile($licenseId: String!) {
  regenerateLicenseFile(input: {id: $licenseId})
}
    `;
export type RegenerateLicenseFileMutationFn = Apollo.MutationFunction<RegenerateLicenseFileMutation, RegenerateLicenseFileMutationVariables>;

/**
 * __useRegenerateLicenseFileMutation__
 *
 * To run a mutation, you first call `useRegenerateLicenseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateLicenseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateLicenseFileMutation, { data, loading, error }] = useRegenerateLicenseFileMutation({
 *   variables: {
 *      licenseId: // value for 'licenseId'
 *   },
 * });
 */
export function useRegenerateLicenseFileMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateLicenseFileMutation, RegenerateLicenseFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateLicenseFileMutation, RegenerateLicenseFileMutationVariables>(RegenerateLicenseFileDocument, options);
      }
export type RegenerateLicenseFileMutationHookResult = ReturnType<typeof useRegenerateLicenseFileMutation>;
export type RegenerateLicenseFileMutationResult = Apollo.MutationResult<RegenerateLicenseFileMutation>;
export type RegenerateLicenseFileMutationOptions = Apollo.BaseMutationOptions<RegenerateLicenseFileMutation, RegenerateLicenseFileMutationVariables>;
export const GenerateLicenseFileDocument = gql`
    mutation GenerateLicenseFile($installationId: String!, $expirationDate: DateTime, $vesselId: String, $comment: String, $users: [LicenseUserInput]) {
  generateLicenseFile(
    input: {installationId: $installationId, expirationDate: $expirationDate, vesselId: $vesselId, comment: $comment, users: $users}
  )
}
    `;
export type GenerateLicenseFileMutationFn = Apollo.MutationFunction<GenerateLicenseFileMutation, GenerateLicenseFileMutationVariables>;

/**
 * __useGenerateLicenseFileMutation__
 *
 * To run a mutation, you first call `useGenerateLicenseFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLicenseFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLicenseFileMutation, { data, loading, error }] = useGenerateLicenseFileMutation({
 *   variables: {
 *      installationId: // value for 'installationId'
 *      expirationDate: // value for 'expirationDate'
 *      vesselId: // value for 'vesselId'
 *      comment: // value for 'comment'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useGenerateLicenseFileMutation(baseOptions?: Apollo.MutationHookOptions<GenerateLicenseFileMutation, GenerateLicenseFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateLicenseFileMutation, GenerateLicenseFileMutationVariables>(GenerateLicenseFileDocument, options);
      }
export type GenerateLicenseFileMutationHookResult = ReturnType<typeof useGenerateLicenseFileMutation>;
export type GenerateLicenseFileMutationResult = Apollo.MutationResult<GenerateLicenseFileMutation>;
export type GenerateLicenseFileMutationOptions = Apollo.BaseMutationOptions<GenerateLicenseFileMutation, GenerateLicenseFileMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($name: String!, $comment: String) {
  createCompany(input: {companyName: $name, comment: $comment}) {
    company {
      ...CompanyBase
    }
    readOnlyConnectionString
  }
}
    ${CompanyBaseFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: Int!) {
  deleteCompany(input: {id: $id}) {
    id
  }
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const GenerateAccessKeyDocument = gql`
    mutation GenerateAccessKey($id: String!) {
  generateAccessKey(input: {vesselId: $id})
}
    `;
export type GenerateAccessKeyMutationFn = Apollo.MutationFunction<GenerateAccessKeyMutation, GenerateAccessKeyMutationVariables>;

/**
 * __useGenerateAccessKeyMutation__
 *
 * To run a mutation, you first call `useGenerateAccessKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAccessKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAccessKeyMutation, { data, loading, error }] = useGenerateAccessKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGenerateAccessKeyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAccessKeyMutation, GenerateAccessKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAccessKeyMutation, GenerateAccessKeyMutationVariables>(GenerateAccessKeyDocument, options);
      }
export type GenerateAccessKeyMutationHookResult = ReturnType<typeof useGenerateAccessKeyMutation>;
export type GenerateAccessKeyMutationResult = Apollo.MutationResult<GenerateAccessKeyMutation>;
export type GenerateAccessKeyMutationOptions = Apollo.BaseMutationOptions<GenerateAccessKeyMutation, GenerateAccessKeyMutationVariables>;
export const UploadConfigDocument = gql`
    mutation UploadConfig($comment: String, $config: Upload!, $flags: Int!, $force: Boolean!, $fromVessel: Boolean!, $vesselId: String) {
  uploadConfig(
    input: {comment: $comment, config: $config, flags: $flags, force: $force, fromVessel: $fromVessel, vesselId: $vesselId}
  ) {
    configBackup {
      id
      vesselId
      fileName
      softwareMajor
      softwareMinor
      softwarePatch
      softwareBuild
      versionHistoryId
      fromVessel
      timeStamp
      comment
      flags
      user
      deleted
    }
    errors {
      __typename
      ... on ConfigError {
        comparison
        message
      }
    }
  }
}
    `;
export type UploadConfigMutationFn = Apollo.MutationFunction<UploadConfigMutation, UploadConfigMutationVariables>;

/**
 * __useUploadConfigMutation__
 *
 * To run a mutation, you first call `useUploadConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadConfigMutation, { data, loading, error }] = useUploadConfigMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      config: // value for 'config'
 *      flags: // value for 'flags'
 *      force: // value for 'force'
 *      fromVessel: // value for 'fromVessel'
 *      vesselId: // value for 'vesselId'
 *   },
 * });
 */
export function useUploadConfigMutation(baseOptions?: Apollo.MutationHookOptions<UploadConfigMutation, UploadConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadConfigMutation, UploadConfigMutationVariables>(UploadConfigDocument, options);
      }
export type UploadConfigMutationHookResult = ReturnType<typeof useUploadConfigMutation>;
export type UploadConfigMutationResult = Apollo.MutationResult<UploadConfigMutation>;
export type UploadConfigMutationOptions = Apollo.BaseMutationOptions<UploadConfigMutation, UploadConfigMutationVariables>;
export const AllVesselsDocument = gql`
    query AllVessels($where: VesselFilterInput, $order: [VesselSortInput!]) {
  vessels(where: $where, order: $order) {
    ...VesselWithCompanyBase
    ...VesselWithConfig
  }
}
    ${VesselWithCompanyBaseFragmentDoc}
${VesselWithConfigFragmentDoc}`;

/**
 * __useAllVesselsQuery__
 *
 * To run a query within a React component, call `useAllVesselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVesselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVesselsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAllVesselsQuery(baseOptions?: Apollo.QueryHookOptions<AllVesselsQuery, AllVesselsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllVesselsQuery, AllVesselsQueryVariables>(AllVesselsDocument, options);
      }
export function useAllVesselsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllVesselsQuery, AllVesselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllVesselsQuery, AllVesselsQueryVariables>(AllVesselsDocument, options);
        }
export function useAllVesselsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllVesselsQuery, AllVesselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllVesselsQuery, AllVesselsQueryVariables>(AllVesselsDocument, options);
        }
export type AllVesselsQueryHookResult = ReturnType<typeof useAllVesselsQuery>;
export type AllVesselsLazyQueryHookResult = ReturnType<typeof useAllVesselsLazyQuery>;
export type AllVesselsSuspenseQueryHookResult = ReturnType<typeof useAllVesselsSuspenseQuery>;
export type AllVesselsQueryResult = Apollo.QueryResult<AllVesselsQuery, AllVesselsQueryVariables>;
export const VesselsDocument = gql`
    query Vessels($id: String!) {
  vessels(where: {id: {eq: $id}}) {
    ...VesselWithCompanyBase
    ...VesselWithConfig
  }
}
    ${VesselWithCompanyBaseFragmentDoc}
${VesselWithConfigFragmentDoc}`;

/**
 * __useVesselsQuery__
 *
 * To run a query within a React component, call `useVesselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVesselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVesselsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVesselsQuery(baseOptions: Apollo.QueryHookOptions<VesselsQuery, VesselsQueryVariables> & ({ variables: VesselsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VesselsQuery, VesselsQueryVariables>(VesselsDocument, options);
      }
export function useVesselsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VesselsQuery, VesselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VesselsQuery, VesselsQueryVariables>(VesselsDocument, options);
        }
export function useVesselsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VesselsQuery, VesselsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VesselsQuery, VesselsQueryVariables>(VesselsDocument, options);
        }
export type VesselsQueryHookResult = ReturnType<typeof useVesselsQuery>;
export type VesselsLazyQueryHookResult = ReturnType<typeof useVesselsLazyQuery>;
export type VesselsSuspenseQueryHookResult = ReturnType<typeof useVesselsSuspenseQuery>;
export type VesselsQueryResult = Apollo.QueryResult<VesselsQuery, VesselsQueryVariables>;
export const ConfigsDocument = gql`
    query Configs($where: ConfigMetaFilterInput, $order: [ConfigMetaSortInput!], $input: ConfigQueryInput) {
  configs(where: $where, order: $order, input: $input) {
    ...Config
  }
}
    ${ConfigFragmentDoc}`;

/**
 * __useConfigsQuery__
 *
 * To run a query within a React component, call `useConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigsQuery(baseOptions?: Apollo.QueryHookOptions<ConfigsQuery, ConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfigsQuery, ConfigsQueryVariables>(ConfigsDocument, options);
      }
export function useConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigsQuery, ConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfigsQuery, ConfigsQueryVariables>(ConfigsDocument, options);
        }
export function useConfigsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConfigsQuery, ConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConfigsQuery, ConfigsQueryVariables>(ConfigsDocument, options);
        }
export type ConfigsQueryHookResult = ReturnType<typeof useConfigsQuery>;
export type ConfigsLazyQueryHookResult = ReturnType<typeof useConfigsLazyQuery>;
export type ConfigsSuspenseQueryHookResult = ReturnType<typeof useConfigsSuspenseQuery>;
export type ConfigsQueryResult = Apollo.QueryResult<ConfigsQuery, ConfigsQueryVariables>;
export const CompaniesDocument = gql`
    query Companies($where: CompanyFilterInput, $order: [CompanySortInput!]) {
  companies(where: $where, order: $order) {
    ...CompanyBase
  }
}
    ${CompanyBaseFragmentDoc}`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export function useCompaniesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesSuspenseQueryHookResult = ReturnType<typeof useCompaniesSuspenseQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const ConfigFilesDocument = gql`
    query ConfigFiles($configId: String!) {
  configFile(configId: $configId)
}
    `;

/**
 * __useConfigFilesQuery__
 *
 * To run a query within a React component, call `useConfigFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigFilesQuery({
 *   variables: {
 *      configId: // value for 'configId'
 *   },
 * });
 */
export function useConfigFilesQuery(baseOptions: Apollo.QueryHookOptions<ConfigFilesQuery, ConfigFilesQueryVariables> & ({ variables: ConfigFilesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfigFilesQuery, ConfigFilesQueryVariables>(ConfigFilesDocument, options);
      }
export function useConfigFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigFilesQuery, ConfigFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfigFilesQuery, ConfigFilesQueryVariables>(ConfigFilesDocument, options);
        }
export function useConfigFilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConfigFilesQuery, ConfigFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConfigFilesQuery, ConfigFilesQueryVariables>(ConfigFilesDocument, options);
        }
export type ConfigFilesQueryHookResult = ReturnType<typeof useConfigFilesQuery>;
export type ConfigFilesLazyQueryHookResult = ReturnType<typeof useConfigFilesLazyQuery>;
export type ConfigFilesSuspenseQueryHookResult = ReturnType<typeof useConfigFilesSuspenseQuery>;
export type ConfigFilesQueryResult = Apollo.QueryResult<ConfigFilesQuery, ConfigFilesQueryVariables>;
export const LicensesQueryDocument = gql`
    query LicensesQuery($where: ConfigLicenseFilterInput, $order: [ConfigLicenseSortInput!]) {
  configLicenses(where: $where, order: $order) {
    ...ConfigLicense
  }
}
    ${ConfigLicenseFragmentDoc}`;

/**
 * __useLicensesQuery__
 *
 * To run a query within a React component, call `useLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useLicensesQuery(baseOptions?: Apollo.QueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicensesQuery, LicensesQueryVariables>(LicensesQueryDocument, options);
      }
export function useLicensesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicensesQuery, LicensesQueryVariables>(LicensesQueryDocument, options);
        }
export function useLicensesQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LicensesQuery, LicensesQueryVariables>(LicensesQueryDocument, options);
        }
export type LicensesQueryHookResult = ReturnType<typeof useLicensesQuery>;
export type LicensesQueryLazyQueryHookResult = ReturnType<typeof useLicensesQueryLazyQuery>;
export type LicensesQuerySuspenseQueryHookResult = ReturnType<typeof useLicensesQuerySuspenseQuery>;
export type LicensesQueryQueryResult = Apollo.QueryResult<LicensesQuery, LicensesQueryVariables>;
export const VesselLicensesDocument = gql`
    query VesselLicenses($id: String!) {
  vesselLicenses(id: $id) {
    ...VesselLicense
  }
}
    ${VesselLicenseFragmentDoc}`;

/**
 * __useVesselLicensesQuery__
 *
 * To run a query within a React component, call `useVesselLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVesselLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVesselLicensesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVesselLicensesQuery(baseOptions: Apollo.QueryHookOptions<VesselLicensesQuery, VesselLicensesQueryVariables> & ({ variables: VesselLicensesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VesselLicensesQuery, VesselLicensesQueryVariables>(VesselLicensesDocument, options);
      }
export function useVesselLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VesselLicensesQuery, VesselLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VesselLicensesQuery, VesselLicensesQueryVariables>(VesselLicensesDocument, options);
        }
export function useVesselLicensesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<VesselLicensesQuery, VesselLicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VesselLicensesQuery, VesselLicensesQueryVariables>(VesselLicensesDocument, options);
        }
export type VesselLicensesQueryHookResult = ReturnType<typeof useVesselLicensesQuery>;
export type VesselLicensesLazyQueryHookResult = ReturnType<typeof useVesselLicensesLazyQuery>;
export type VesselLicensesSuspenseQueryHookResult = ReturnType<typeof useVesselLicensesSuspenseQuery>;
export type VesselLicensesQueryResult = Apollo.QueryResult<VesselLicensesQuery, VesselLicensesQueryVariables>;