import {
  DisplayProcessor,
  DisplayValue,
  DisplayValueAlignmentFactors,
  Field,
  FieldConfig,
  FieldType,
  ThresholdsMode,
  VizOrientation,
  getDisplayProcessor,
} from '@grafana/data';
import { BarGauge, BarGaugeDisplayMode, Themeable2, VizTextDisplayOptions, useTheme2 } from '@grafana/ui';
import React from 'react';

interface Props {
  value: number;
  width: number;
  height: number;
}

enum BarGaugeValueMode {
  Color = 'color',
  Hidden = 'hidden',
  Text = 'text',
}

export enum BarGaugeNamePlacement {
  Auto = 'auto',
  Left = 'left',
  Top = 'top',
}

interface BarGaugeProps extends Themeable2 {
  height: number;
  width: number;
  field: FieldConfig;
  display?: DisplayProcessor;
  value: DisplayValue;
  orientation: VizOrientation;
  text?: VizTextDisplayOptions;
  itemSpacing?: number;
  lcdCellWidth?: number;
  displayMode: BarGaugeDisplayMode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  showUnfilled?: boolean;
  alignmentFactors?: DisplayValueAlignmentFactors;
  valueDisplayMode?: BarGaugeValueMode;
  namePlacement?: BarGaugeNamePlacement;
}

export const ProgressBar: React.FC<Props> = ({ value, width, height }) => {
  const theme = useTheme2();
  const field: Partial<Field> = {
    type: FieldType.number,
    config: {
      min: 1,
      max: 100,
      thresholds: {
        mode: ThresholdsMode.Absolute,
        steps: [{ value: -Infinity, color: 'blue' }],
      },
    },
  };
  field.display = getDisplayProcessor({ field, theme });

  const props: Partial<BarGaugeProps> = {
    theme,
    lcdCellWidth: 8,
    itemSpacing: 2,
    showUnfilled: true,
    width: width,
    height: height,
    value: {
      text: `${Math.round(value)} %`,
      title: '',
      numeric: value,
    },
    displayMode: BarGaugeDisplayMode.Lcd,
    orientation: VizOrientation.Horizontal,
    field: field.config!,
    display: field.display!,
  };

  return <BarGauge {...props} />;
};
