import { AppRootProps, NavModelItem } from '@grafana/data';
import { CompanyPage } from './CompanyPage';
import { VesselPage } from './VesselPage';
import { LicensePage } from './LicensePage';
import { MonsysPage } from './MonsysPage';

export interface PageDefinition extends NavModelItem {
  component: React.FC<AppRootProps>;
}

export const pages: PageDefinition[] = [
  {
    component: CompanyPage,
    icon: 'building',
    id: 'companies',
    text: 'Companies',
  },
  {
    component: VesselPage,
    icon: 'anchor',
    id: 'vessels',
    text: 'Vessels',
  },
  {
    component: LicensePage,
    icon: 'key-skeleton-alt',
    id: 'licenses',
    text: 'Licenses',
  },
  {
    component: MonsysPage,
    icon: 'cog',
    id: 'monSysConfigs',
    text: 'Config files',
  },
];
