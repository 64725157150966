import React, { FC, useState, useMemo, useCallback } from 'react';

import { cx } from '@emotion/css';
import {
  Form,
  Field,
  Input,
  Button,
  Checkbox,
  Spinner,
  VerticalGroup,
  HorizontalGroup,
  Card,
  useStyles2,
  TextArea,
  Select,
  IconButton,
  InputControl,
  DatePickerWithInput,
} from '@grafana/ui';
import { LicenseUser, LicenseVariables } from 'types';
import { getStyles } from 'utils/styles';
import { SelectableValue } from '@grafana/data';
import { UserEntry } from './UserEntry';
import { VesselWithCompanyBaseFragment, VesselWithConfigFragment, useAllVesselsQuery } from '__generated__/__admin';
import { notEmpty, getSearchPredicate } from 'helpers';

interface Props {
  onSubmit: (variables: LicenseVariables, vessel: string) => void;
  loading?: boolean;
}

const FIELDS_TO_SEARCH: Array<keyof VesselItem> = ['name', 'imo', 'comment', 'id'];

const filterVessels = (option: SelectableValue<VesselItem>, searchQuery: string): boolean => {
  if (!searchQuery || !option.value) {
    return true;
  }

  return FIELDS_TO_SEARCH.some(getSearchPredicate(option.value, searchQuery.toLowerCase()));
};

type VesselItem = VesselWithCompanyBaseFragment & VesselWithConfigFragment;

export const LicenseOperation: FC<Props> = ({ onSubmit, loading }) => {
  const [showExpiration, setShowExpiration] = useState(true);
  const [users, setUsers] = useState<LicenseUser[]>([]);

  const minDate = useMemo<Date>(() => {
    let returnDate = new Date();
    returnDate.setDate(returnDate.getDate() + 1);

    return returnDate;
  }, []);

  const styles = useStyles2(getStyles());

  const { data: vesselsData, loading: vesselsLoading } = useAllVesselsQuery();

  const selectValues = useMemo(() => {
    return (
      (vesselsData?.vessels
        ?.filter(notEmpty)
        .map((e) => ({ label: `${e.name}${e.imo ? ` (IMO: ${e.imo})` : ''}`, value: e })) as Array<
        SelectableValue<VesselItem>
      >) || []
    );
  }, [vesselsData]);

  const setVariables = (variables: LicenseVariables) => {
    if (users.length > 0) {
      variables.users = users;
    }
    onSubmit(variables, variables.vesselId);
  };

  const deleteUser = useCallback((variables: LicenseUser) => {
    setUsers((e) => e.filter((u) => u.username !== variables.username));
  }, []);

  const onUserSubmit = useCallback(
    (variables: LicenseUser) => {
      if (users.find((u) => u.username === variables.username)) {
        return;
      }

      setUsers((e) => [...e, variables]);
    },
    [users]
  );

  return (
    <HorizontalGroup
      // @ts-ignore
      align="stretch"
    >
      <Card className={styles.card}>
        <Card.Heading>License</Card.Heading>
        <Card.Description>
          <Form<LicenseVariables> onSubmit={setVariables}>
            {({ register, errors, control }) => {
              return (
                <VerticalGroup align="flex-start">
                  <Field
                    label="Installation ID"
                    invalid={!!errors.installationId}
                    error={errors.installationId?.message}
                  >
                    <Input
                      {...register('installationId', {
                        required: 'This is required',
                        pattern: {
                          value: /^[A-Za-z0-9]{5}-[A-Za-z0-9]{5}$/,
                          message: 'Invalid format. Expected: XXXXX-XXXXX',
                        },
                      })}
                      placeholder="ABC12-3DEF4"
                      width={25}
                    />
                  </Field>
                  <Checkbox label="Expiration" checked={showExpiration} onChange={() => setShowExpiration((e) => !e)} />
                  {showExpiration && (
                    <Field
                      label="Expiration Date"
                      invalid={!!errors.expirationDate}
                      error={errors.expirationDate?.message}
                    >
                      <InputControl
                        render={({ field }) => (
                          <DatePickerWithInput {...field} placeholder="01/01/1970" width={25} minDate={minDate} />
                        )}
                        rules={{ required: 'This is required' }}
                        name="expirationDate"
                        control={control}
                      />
                    </Field>
                  )}
                  <Field label="Vessel" invalid={!!errors.vesselId} error={errors.vesselId?.message}>
                    <InputControl
                      render={({ field }) => (
                        <Select<VesselItem>
                          width={25}
                          onChange={(e) => field.onChange(e.value?.id)}
                          value={selectValues?.find((e) => e.value?.id === field.value)}
                          options={selectValues}
                          filterOption={filterVessels}
                          isLoading={vesselsLoading}
                          virtualized
                          placeholder="Vessel (IMO: 1234567)"
                        />
                      )}
                      rules={{ required: 'This is required' }}
                      name="vesselId"
                      control={control}
                    />
                  </Field>
                  <Field label="Comment">
                    <TextArea rows={5} cols={23} {...register('comment')} />
                  </Field>
                  <HorizontalGroup justify="flex-end">
                    <Button disabled={loading} variant="primary" type="submit">
                      {loading ? <Spinner /> : 'Create'}
                    </Button>
                  </HorizontalGroup>
                </VerticalGroup>
              );
            }}
          </Form>
        </Card.Description>
      </Card>
      <Card className={cx(styles.card, styles.userCard)}>
        <Card.Heading>Users</Card.Heading>
        <Card.Description>
          <UserEntry onSubmit={onUserSubmit} />
          <table>
            <tr>
              <td>
                <table className={styles.table}>
                  <tr>
                    <th className={styles.userCardHeader}>Username</th>
                    <th className={styles.userCardHeader}>Password</th>
                    <th></th>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <div className={styles.userCardTable}>
                <table className={styles.table}>
                  {users.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <Input width={20} value={user.username} disabled={true} />
                      </td>
                      <td>
                        <Input width={20} value={user.password} disabled={true} />
                      </td>
                      <td className={styles.userCardAction}>
                        <IconButton variant="secondary" onClick={() => deleteUser(user)} name="trash-alt" size="lg" />
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </tr>
          </table>
        </Card.Description>
      </Card>
    </HorizontalGroup>
  );
};
