import { ComponentClass } from 'react';
import { AppPlugin, AppRootProps } from '@grafana/data';
import { RootPage } from './RootPage';
import { ConfigPage } from 'pages/ConfigPage';

export interface AdminAppSettings {
  datasource?: string;
}

const getPlugin = () => {
  let basePlugin = new AppPlugin<AdminAppSettings>()
    .setRootPage(RootPage as unknown as ComponentClass<AppRootProps>)
    .addConfigPage({
      title: 'Settings',
      icon: 'cog',
      body: ConfigPage,
      id: 'settings',
    });
  return basePlugin;
};

export const plugin = getPlugin();
