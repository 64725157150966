import React, { useCallback, useState } from 'react';
import { Button, HorizontalGroup } from '@grafana/ui';
import { DisablePluginModal } from './DisablePluginModal';

interface Props {
  disabled?: boolean;
  enabled?: boolean;
  onEnable: () => void;
  onUpdate: () => void;
  onDisable: () => void;
}

export const ConfigActions: React.FC<Props> = ({ disabled, enabled, onDisable, onEnable, onUpdate }) => {
  const [showDisableModal, setShowDisableModal] = useState(false);

  const getAction = useCallback(() => {
    if (!enabled) {
      return (
        <Button variant="primary" onClick={onEnable} disabled={disabled}>
          Enable plugin
        </Button>
      );
    }

    return (
      <HorizontalGroup>
        <Button variant="primary" onClick={onUpdate}>
          Update
        </Button>
        <Button variant="destructive" onClick={() => setShowDisableModal(true)}>
          Disable
        </Button>
      </HorizontalGroup>
    );
  }, [enabled, disabled, onEnable, onUpdate]);

  return (
    <>
      {getAction()}
      <DisablePluginModal
        isOpen={showDisableModal}
        onDismiss={() => setShowDisableModal(false)}
        onConfirm={() => {
          setShowDisableModal(false);
          onDisable();
        }}
      />
    </>
  );
};
