import { getBackendSrv } from '@grafana/runtime';

export async function fetchPluginSettings<T>(pluginId: string) {
  const res = await getBackendSrv().get(`api/plugins/${pluginId}/settings`);
  return res.jsonData as T;
}

export async function updatePluginSettings<T>(pluginId: string, settings: T) {
  const pluginSettings = await getBackendSrv().get(`api/plugins/${pluginId}/settings`);
  await getBackendSrv().post(`api/plugins/${pluginId}/settings`, {
    ...pluginSettings,
    jsonData: {
      ...(pluginSettings.jsonData ?? {}),
      ...settings,
    },
  });
}
