import React, { useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useHistory } from 'react-router-dom';
import { useStyles2, useTheme2, HorizontalGroup, IconButton, Table } from '@grafana/ui';
import { getStyles } from 'utils/styles';
import { CompaniesQuery, CompanyBaseFragment } from '__generated__/__admin';
import { notEmpty } from 'helpers';
import { field } from 'utils/field';
import { MutableDataFrame, applyFieldOverrides } from '@grafana/data';
import { EditCompanyModal } from './EditCompanyModal';

interface Props {
  data?: CompaniesQuery;
}

export const CompaniesTable: React.FC<Props> = ({ data }) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles());
  const [editId, setEditId] = useState<number>();
  const tableData = useMemo(() => {
    const td: MutableDataFrame = new MutableDataFrame({
      fields: [
        field<string>('Action', { width: 80, sortable: false }),
        field<string>('Company name'),
        field<string>('Grafana Org'),
        field<string>('Comment'),
      ],
    });
    data?.companies?.filter(notEmpty).forEach((c) => {
      td.appendRow([
        <CompanyActions key={c.id} company={c} onEdit={(id) => setEditId(id)} />,
        c.companyName,
        c.grafanaOrgId,
        c.comment,
      ]);
    });
    return td;
  }, [data]);

  const dataFrame = useMemo(() => {
    return applyFieldOverrides({
      data: [tableData],
      fieldConfig: {
        overrides: [],
        defaults: {},
      },
      theme,
      replaceVariables: (value: string) => value,
    })[0];
  }, [tableData, theme]);

  return (
    <>
      <EditCompanyModal companyId={editId} onDismiss={() => setEditId(undefined)} />
      <div className={styles.wrap}>
        <div className={styles.content}>
          <AutoSizer disableHeight className={styles.spacing}>
            {({ width }) => {
              if (width === 0) {
                return null;
              }

              return (
                <div style={{ width }}>
                  <Table height={700} width={width} enablePagination data={dataFrame} />
                </div>
              );
            }}
          </AutoSizer>
        </div>
      </div>
    </>
  );
};

const CompanyActions: React.FC<{ company: CompanyBaseFragment; onEdit: (id: number) => void }> = ({
  company,
  onEdit,
}) => {
  const history = useHistory();
  return (
    <div style={{ marginLeft: '5px' }}>
      <HorizontalGroup height={30}>
        <IconButton style={{ cursor: 'pointer' }} name="eye" onClick={() => onEdit(company.id)} tooltip="Edit" />
        <IconButton
          style={{ cursor: 'pointer' }}
          name="anchor"
          onClick={() => {
            // replace the url variables with tab = 'vessels' and companyId = company.id
            // then navigate to that url
            history.push({
              search: `?tab=vessels&companyId=${company.id}`,
            });
          }}
          tooltip="View vessels"
        />
      </HorizontalGroup>
    </div>
  );
};
