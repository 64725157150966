import React, { useState, useCallback, useEffect } from 'react';

import { AppPluginMeta, GrafanaPlugin } from '@grafana/data';
import { Alert, Input } from '@grafana/ui';
import { getBackendSrv } from '@grafana/runtime';

import { AdminAppSettings } from '../module';
import { fetchPluginSettings, updatePluginSettings } from 'utils/initialization';
import { ConfigActions } from './ConfigActions';

interface Props {
  plugin: GrafanaPlugin<AppPluginMeta<AdminAppSettings>>;
}

export const Setup: React.FC<Props> = ({ plugin }) => {
  const [settings, setSettings] = useState<AdminAppSettings>();
  const [selectedDatasource, setSelectedDatasource] = useState<string>();

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await fetchPluginSettings<AdminAppSettings>(plugin.meta.id);
      setSettings(settings);
    };
    fetchSettings();
  }, [plugin]);

  useEffect(() => {
    setSelectedDatasource(settings?.datasource ?? '');
  }, [settings]);

  const handleEnable = useCallback(
    (enable = true) => {
      const enableAction = async (enable: boolean) => {
        await getBackendSrv()
          .fetch({
            url: `/api/plugins/${plugin.meta.id}/settings`,
            method: 'POST',
            data: {
              enabled: enable ? true : false,
              pinned: enable ? true : false,
            },
          })
          .toPromise();
        await updatePluginSettings(plugin.meta.id, {
          datasource: enable ? selectedDatasource : undefined,
        });
        window.location.reload();
      };
      enableAction(enable);
    },
    [plugin.meta.id, selectedDatasource]
  );

  const handleDisable = useCallback(() => {
    handleEnable(false);
  }, [handleEnable]);

  const handleUpdate = useCallback(() => {
    updatePluginSettings(plugin.meta.id, { datasource: selectedDatasource });
  }, [plugin.meta.id, selectedDatasource]);

  return (
    <div>
      <Alert severity="info" title="Choose datasource">
        <p>URL to datasource:</p>
        <Input onChange={(v) => setSelectedDatasource(v.currentTarget.value)} value={selectedDatasource} />
      </Alert>
      <ConfigActions
        disabled={selectedDatasource === undefined}
        enabled={plugin.meta.enabled}
        onDisable={handleDisable}
        onEnable={handleEnable}
        onUpdate={handleUpdate}
      />
    </div>
  );
};
