import React, { FC, useCallback } from 'react';

import { VesselOperation } from './VesselOperation';
import { useAppNotification } from 'notifications';
import { Modal } from '@grafana/ui';
import {
  AllVesselsDocument,
  UpdateVesselMutationVariables,
  VesselsDocument,
  useUpdateVesselMutation,
} from '__generated__/__admin';

interface Props {
  onDismiss?: () => void;
  vesselId?: string;
}

export const EditVesselModal: FC<Props> = ({ vesselId, onDismiss }) => {
  const notifier = useAppNotification();
  const [updateVesselMutation, { loading }] = useUpdateVesselMutation({
    refetchQueries: [{ query: AllVesselsDocument }, { query: VesselsDocument, variables: { id: vesselId } }],
  });

  const onUpdateVessel = useCallback(
    (variables: Omit<UpdateVesselMutationVariables, 'id'>) => {
      const asyncFunc = async (variables: UpdateVesselMutationVariables) => {
        try {
          const res = await updateVesselMutation({ variables });
          if (res.errors) {
            notifier.error(`Vessel update failed`);
          } else {
            notifier.success(`Vessel updated`);
            onDismiss?.();
          }
        } catch (e) {
          console.error(e);
          notifier.error(`Vessel update failed`);
        }
      };
      if (!loading && vesselId) {
        asyncFunc({ ...variables, id: vesselId });
      }
    },
    [updateVesselMutation, loading, vesselId, notifier, onDismiss]
  );
  return (
    <Modal closeOnEscape title="Edit vessel" isOpen={!!vesselId} onDismiss={onDismiss}>
      <VesselOperation vesselId={vesselId} onSubmit={onUpdateVessel} loading={loading} />
    </Modal>
  );
};
