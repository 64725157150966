import React, { FC } from 'react';
import { ConfirmButton, Field, Form, VerticalGroup } from '@grafana/ui';
import { useGenerateAccessKeyMutation } from '__generated__/__admin';
import { CopyInput } from 'components/CopyInput';

interface Props {
  id: string;
  cloud: boolean;
}

export const AccessKeyGenerator: FC<Props> = ({ id, cloud }) => {
  const [generateAccessKey, { data, loading }] = useGenerateAccessKeyMutation();

  return (
    <Form
      onSubmit={() => {
        return;
      }}
    >
      {() => (
        <VerticalGroup spacing="sm">
          <Field
            label="Vessel id"
            description="This is the id of the vessel, it is used to identify the vessel in the cloud and in MonSys"
          >
            <CopyInput text={id} />
          </Field>
          {cloud && (
            <Field
              label="Generate cloud access key"
              description="Generates new cloud access key for this vessel, the old one will become obsolete"
            >
              {data?.generateAccessKey ? (
                <CopyInput text={data.generateAccessKey} />
              ) : (
                <ConfirmButton
                  closeOnConfirm
                  confirmText="Are you sure?"
                  disabled={loading || data !== undefined}
                  onConfirm={() => {
                    if (!loading) {
                      generateAccessKey({ variables: { id: id } });
                    }
                  }}
                >
                  {'Generate new key'}
                </ConfirmButton>
              )}
            </Field>  
          )}
          
        </VerticalGroup>
      )}
    </Form>
  );
};
