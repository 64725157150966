import React, { FC, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useTheme2, HorizontalGroup, Table, useStyles2, IconButton } from '@grafana/ui';
import { MutableDataFrame, applyFieldOverrides, dateTime } from '@grafana/data';
import { field } from 'utils/field';
import { ConfigFragment } from '__generated__/__admin';
import { getStyles } from 'utils/styles';

interface Props {
  configs?: readonly ConfigFragment[];
  showAllVersions?: boolean;
  getDownloadLink: (id: string) => void;
}

export const ConfigsTable: FC<Props> = ({ configs, getDownloadLink }) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles());

  const tableData = useMemo(() => {
    const td: MutableDataFrame = new MutableDataFrame({
      fields: [
        field<string>('Action', { width: 80, sortable: false }),
        field<string>('IMO', { width: 80 }),
        field<string>('Vessel', { width: 120 }),
        field<number>('Date', { width: 100 }),
        field<string>('Version', { width: 80 }),
        field<string>('Software version', { width: 120 }),
        field<string>('User', { width: 120 }),
        field<string>('Comment'),
      ],
    });

    configs?.forEach((config) => {
      if (config) {
        td.appendRow([
          <ConfigActions key={config.id} id={config.id} getDownloadLink={getDownloadLink} />,
          config.vessel?.imo,
          config.vessel?.name,
          config.timeStamp ? dateTime(config.timeStamp).format('DD.MM.YYYY') : '',
          config.versionHistoryId,
          softwareVersionString(config),
          config.user,
          config.comment,
        ]);
      }
    });
    return applyFieldOverrides({
      data: [td],
      fieldConfig: {
        overrides: [],
        defaults: {},
      },
      theme,
      replaceVariables: (value: string) => value,
    })[0];
  }, [configs, getDownloadLink, theme]);

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <AutoSizer disableHeight className={styles.spacing}>
          {({ width }) => {
            if (width === 0) {
              return null;
            }

            return (
              <div style={{ width }}>
                {tableData && <Table height={700} width={width} enablePagination data={tableData} />}
              </div>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

const softwareVersionString = (softwareVersion: ConfigFragment): string =>
  `${softwareVersion.softwareMajor}.${softwareVersion.softwareMinor}.${softwareVersion.softwarePatch}.${softwareVersion.softwareBuild}`;

const ConfigActions: FC<{ id: string | null; getDownloadLink: (id: string) => void }> = ({ id, getDownloadLink }) => {
  return (
    <div style={{ marginLeft: '5px' }}>
      <HorizontalGroup height={30}>
        <IconButton
          style={{ cursor: 'pointer' }}
          name="download-alt"
          tooltip="Download config file"
          onClick={() => {
            if (id) {
              getDownloadLink(id);
            }
          }}
        />
      </HorizontalGroup>
    </div>
  );
};
