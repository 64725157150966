import React, { useMemo, useState, useCallback, useLayoutEffect, useRef } from 'react';
import {
  Button,
  ClickOutsideWrapper,
  HorizontalGroup,
  IconButton,
  Input,
  Label,
  VerticalGroup,
  useStyles2,
} from '@grafana/ui';
import { css, cx } from '@emotion/css';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { getFilteredOptions } from 'utils/filter';
import { FilterList } from './FilterList';

interface BaseProps<T> {
  onClose: () => void;
  setFilter: (filter: T | undefined) => void;
  label?: string;
}

interface PropsWithOptions<T> extends BaseProps<Array<SelectableValue<T>>> {
  options: Array<SelectableValue<T>>;
  filterValue: Array<SelectableValue<T>>;
}

interface PropsWithoutOptions<T extends string | number> extends BaseProps<T> {
  filterValue: T;
}

type Props<T> = T extends string | number ? PropsWithoutOptions<string | number> : PropsWithOptions<T>;

export const FilterPopup = <T,>(props: Props<T>) => {
  const hasOptions = 'options' in props;
  if (hasOptions) {
    return FilterPopupWithOptions(props);
  }
  // Check if props is propstwithoutoptions<string>
  if (typeof props.filterValue === 'string') {
    return FilterPopupWithoutOptions(props);
  }
  return;
};

const FilterPopupWithoutOptions = ({
  onClose,
  setFilter,
  filterValue,
  label,
}: PropsWithoutOptions<string | number>) => {
  const styles = useStyles2(getStyles);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <ClickOutsideWrapper onClick={onClose} useCapture={true}>
      <div className={cx(styles.filterContainer)} onClick={stopPropagation}>
        <VerticalGroup spacing="lg">
          <VerticalGroup spacing="xs">
            <HorizontalGroup justify="space-between" align="center">
              <Label className={styles.label}>{label || 'Filter by values:'}</Label>
            </HorizontalGroup>
            <div className={cx(styles.listDivider)} />
            <Input
              ref={inputRef}
              placeholder="Filter values"
              onChange={(e) => setFilter(e.currentTarget.value)}
              defaultValue={filterValue}
              suffix={
                <IconButton
                  name="times"
                  tooltip="Clear"
                  onClick={(e) => {
                    if (inputRef.current) {
                      inputRef.current.value = '';
                    }
                    setFilter(e.currentTarget.value);
                  }}
                />
              }
            />
          </VerticalGroup>
        </VerticalGroup>
      </div>
    </ClickOutsideWrapper>
  );
};

const FilterPopupWithOptions = <T,>({ options, onClose, setFilter, label, filterValue }: PropsWithOptions<T>) => {
  const filteredOptions = useMemo(() => getFilteredOptions(options, filterValue), [options, filterValue]);
  const [values, setValues] = useState<SelectableValue[]>(filteredOptions);
  
  const onFilter = useCallback(() => {
    const filtered = values.length ? values : undefined;

    setFilter(filtered);
    onClose();
  }, [setFilter, values, onClose]);

  const onClearFilter = useCallback(() => {
    setFilter(undefined);
    onClose();
  }, [setFilter, onClose]);

  useLayoutEffect(() => {
    setValues(filteredOptions);
  }, [filteredOptions]);

  const clearFilterVisible = useMemo(() => filterValue !== undefined, [filterValue]);
  const styles = useStyles2(getStyles);
  return (
    <ClickOutsideWrapper onClick={onClose} useCapture={true}>
      <div className={cx(styles.filterContainer)} onClick={stopPropagation}>
        <VerticalGroup spacing="lg">
          <VerticalGroup spacing="xs">
            <HorizontalGroup justify="space-between" align="center">
              <Label className={styles.label}>{label || 'Filter by values'}</Label>
            </HorizontalGroup>
            <div className={cx(styles.listDivider)} />
            <FilterList onChange={setValues} values={values} options={options} />
          </VerticalGroup>
          <HorizontalGroup spacing="lg">
            <HorizontalGroup>
              <Button size="sm" onClick={onFilter}>
                Ok
              </Button>
              <Button size="sm" variant="secondary" onClick={onClose}>
                Cancel
              </Button>
            </HorizontalGroup>
            {clearFilterVisible && (
              <HorizontalGroup>
                <Button fill="text" size="sm" onClick={onClearFilter}>
                  Clear filter
                </Button>
              </HorizontalGroup>
            )}
          </HorizontalGroup>
        </VerticalGroup>
      </div>
    </ClickOutsideWrapper>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  filterContainer: css({
    label: 'filterContainer',
    width: '100%',
    minWidth: '250px',
    height: '100%',
    maxHeight: '400px',
    backgroundColor: theme.colors.background.primary,
    border: `1px solid ${theme.colors.border.weak}`,
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0),
    boxShadow: theme.shadows.z3,
    borderRadius: theme.shape.borderRadius(1),
  }),
  listDivider: css({
    label: 'listDivider',
    width: '100%',
    borderTop: `1px solid ${theme.colors.border.medium}`,
    padding: theme.spacing(0.5, 2),
  }),
  label: css({
    marginBottom: 0,
  }),
});

const stopPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};
