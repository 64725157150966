import React, { FC, useState, useCallback } from 'react';
import { Button, Modal } from '@grafana/ui';

import { LicenseOperation } from './LicenseOperation';
import { DownloadLicenseJsonInput, LicenseVariables } from 'types';
import { useAppNotification } from 'notifications';
import { useGenerateLicenseFileMutation } from '__generated__/__admin';

interface Props {
  refetch: () => void;
  downloadJson: (input: DownloadLicenseJsonInput) => void;
}

export const AddLicense: FC<Props> = ({ downloadJson, refetch }) => {
  const notifier = useAppNotification();
  const [isOpen, setIsOpen] = useState(false);

  const [generateLicenseFile, { loading }] = useGenerateLicenseFileMutation();

  const onAddLicense = useCallback(
    (variables: LicenseVariables, vessel: string) => {
      const asyncFunc = async (variables: LicenseVariables) => {
        try {
          if (!loading) {
            const res = await generateLicenseFile({ variables });
            if (res.errors) {
              notifier.error('License creation failed');
            } else {
              notifier.success('License created');
              downloadJson({
                json: res.data?.generateLicenseFile ?? undefined,
                vessel: vessel,
                installationId: variables.installationId,
              });
              refetch();
              setIsOpen(false);
            }
          }
        } catch (e) {
          notifier.error('Failed creating license');
        }
      };
      asyncFunc(variables);
    },
    [generateLicenseFile, loading, notifier, downloadJson, refetch]
  );

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Add</Button>
      <Modal title="Create License" isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <LicenseOperation onSubmit={onAddLicense} loading={loading} />
      </Modal>
    </>
  );
};
