import { ApplicationType } from '__generated__/__admin';

export const humanizeApplicationNameHelper = (applicationType: ApplicationType): string => {
    switch (applicationType) {
        case ApplicationType.CARGOMASTER:
            return 'CARGOMASTER';
        case ApplicationType.ECOMATE:
            return 'EcoMATE Cloud';
        case ApplicationType.COMPACT:
            return 'Compact';
        case ApplicationType.ECOMATE_STANDALONE:
            return 'EcoMATE Standalone';
        default:
            return 'Unknown';
    }
}
