import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Field, Input, HorizontalGroup, Button, VerticalGroup } from '@grafana/ui';
import { LicenseUser } from 'types';

interface Props {
  onSubmit: (variables: LicenseUser) => void;
}

export const UserEntry: FC<Props> = (props) => {
  const { reset, handleSubmit, register, formState } = useForm<LicenseUser>();

  const onSubmit = (variables: LicenseUser) => {
    props.onSubmit(variables);
    reset({ ...variables });
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ username: '', password: '' });
    }
  }, [formState, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VerticalGroup>
        <HorizontalGroup>
          <Field label="Username">
            <Input width={25} {...register('username', { required: 'This is required' })} />
          </Field>
          <Field label="Password">
            <Input width={25} {...register('password', { required: 'This is required' })} />
          </Field>
        </HorizontalGroup>
        <HorizontalGroup justify="flex-end">
          <Button variant="primary" type="submit">
            Add
          </Button>
        </HorizontalGroup>
      </VerticalGroup>
    </form>
  );
};
