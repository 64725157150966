import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';

export const getStyles = () => (theme: GrafanaTheme2) => {
  return {
    wrap: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      flex: 1 1 0;
    `,
    content: css`
      flex-grow: 1;
      height: 100%;
      overflow-x: scroll;
    `,
    spacing: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    verticalSpacing: css`
      margin: ${theme.spacing('0.5em', 0)};
    `,
    alert: css`
      z-index: ${theme.zIndex.modal};
      position: absolute;
      top: 10px;
      right: 10px;
      max-width: 50em;
    `,
    companyWrapper: css`
      width: 600px;
    `,
    table: css`
      width: 100%;
      overflow-y: auto;

      th {
        border-bottom: 2px solid ${theme.colors.border.strong};
        font-weight: ${theme.typography.fontWeightBold};
      }

      td {
        border-bottom: 1px solid ${theme.colors.border.medium};
      }

      th,
      td {
        color: ${theme.colors.text.primary};
        padding: 8px;
      }
    `,
    cardWrapper: css`
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
    `,
    card: css`
      display: flex;
      flex-direction: column;
    `,
    userCard: css`
      height: 510px;
    `,
    userCardAction: css`
      width: 45px;
      text-align: center;
      vertical-align: middle;

      button {
        margin: 5px;
      }
    `,
    userCardTable: css`
      height: 290px;
      overflow-y: auto;
    `,
    userCardHeader: css`
      width: 176px;
    `,
  };
};
