import { FieldType, MutableVector } from '@grafana/data';

type FieldOptions = {
  filterable?: boolean;
  type?: FieldType;
  sortable?: boolean;
  width?: number;
  unit?: string;
  noValue?: string;
};

export function field<T>(name: string, options?: FieldOptions) {
  return {
    name,
    type: options?.type || FieldType.string,
    values: [] as any as MutableVector<T>,
    config: {
      unit: options?.unit,
      custom: {
        noValue: options?.noValue,
        sortable: options?.sortable ?? true,
        filterable: options?.filterable ?? false,
        width: options?.width,
      },
    },
  };
}
