import React, { FC, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AppRootProps } from '@grafana/data';
import { HorizontalGroup, IconButton, Input } from '@grafana/ui';

import { UploadConfig } from 'components/monsys-config/UploadConfigModal';
import { ConfigsTable } from 'components/monsys-config/ConfigsTable';
import {
  ConfigMetaFilterInput,
  InputMaybe,
  useConfigFilesLazyQuery,
  useConfigsQuery,
  useVesselsLazyQuery,
} from '__generated__/__admin';
import { useAppNotification } from 'notifications';
import { notEmpty } from 'helpers';
import { filterToWhere } from 'utils/filter';
import { Mutable } from 'types';
import { Filter } from 'components/filter/Filter';

export const MonsysPage: FC<AppRootProps> = () => {
  const notifier = useAppNotification();
  const { search } = useLocation();
  const history = useHistory();
  const vesselId = useMemo(() => new URLSearchParams(search).get('vesselId'), [search]);
  const [vesselFilter, setVesselFilter] = useState<string>();
  const [userFilter, setUserFilter] = useState<string>();

  const [vesselsQuery, { data: vesselData }] = useVesselsLazyQuery();

  useLayoutEffect(() => {
    if (vesselId) {
      vesselsQuery({ variables: { id: vesselId } });
    }
  }, [vesselsQuery, vesselId]);

  const where = useMemo(() => {
    const vesselWhere = filterToWhere(vesselFilter);
    const userWhere = filterToWhere(userFilter);

    const res: InputMaybe<Mutable<ConfigMetaFilterInput>> = {};

    if (vesselId) {
      res.vesselId = { eq: vesselId };
    }
    if (vesselWhere) {
      res.vessel = { name: vesselWhere };
    }
    if (userWhere) {
      res.user = userWhere;
    }
    if (Object.keys(res).length === 0) {
      return undefined;
    }
    return res as InputMaybe<ConfigMetaFilterInput>;
  }, [vesselId, vesselFilter, userFilter]);

  const { data: configData } = useConfigsQuery({ variables: { input: { allVersions: true }, where } });

  const [configFileQuery] = useConfigFilesLazyQuery();

  const handleGetDownloadLink = useCallback(
    (id: string) => {
      const vessel = configData?.configs?.filter(notEmpty).find((c) => c.id === id)?.vessel;
      configFileQuery({
        variables: { configId: id },
        onCompleted: (data) => {
          if (data.configFile) {
            const link = document.createElement('a');
            link.href = data.configFile;
            if (vessel?.name) {
              link.setAttribute('download', `${vessel.name.replace(/\s/g, '_')}_config.db`);
            } else {
              link.setAttribute('download', 'config.db');
            }
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        },
        onError: () => {
          notifier.error('Failed to download config file');
        },
      });
    },
    [configFileQuery, configData, notifier]
  );

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        <HorizontalGroup justify="space-between">
          <UploadConfig />
          <HorizontalGroup>
            {vesselId && vesselData?.vessels?.[0]?.name ? (
              <Input
                value={vesselData.vessels[0].name}
                disabled
                prefix="Vessel: "
                suffix={
                  <IconButton
                    name="times"
                    tooltip="Remove this filter"
                    onClick={() => {
                      history.push({ search: '?tab=licenses' });
                    }}
                  />
                }
              />
            ) : (
              <Filter<string> label="Vessel" setFilter={(e) => setVesselFilter(e)} filter={vesselFilter || ''} />
            )}
            <Filter<string> label="User" filter={userFilter || ''} setFilter={setUserFilter} />
          </HorizontalGroup>
        </HorizontalGroup>
      </div>
      <ConfigsTable configs={configData?.configs?.filter(notEmpty)} getDownloadLink={handleGetDownloadLink} />
    </>
  );
};
